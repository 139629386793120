body.light_mode {
    &::-webkit-scrollbar {
        background-color: #DDD;
    }

    #root {
        background-color: #DDD;
    }

    /*--- UI ---*/

    .balance_alert_wrapper .balance_alert {
        svg:not(.remove) path {
            stroke: $base-color-dark;
        }

        .title,
        .subtitle {
            color: $base-color-dark;
        }

        svg.remove path,
        svg.remove rect {
            fill: $base-color-dark;
        }
    }

    .svg-view_password path {
        fill: $base-color-dark-grey-2;
        opacity: .65;
    }

    .sort_wrapper {
        .sort_block {
            background-color: #FFF;

            .sort_btn span {
                color: $base-color-grey;
            }
        }

        .sort_window {
            background-color: #FFF;

            .input_wrapper {
                input::after {
                    background-color: #FFF;
                }

                input:checked::after {
                    background: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%279%27 viewBox=%270 0 12 9%27 fill=%27none%27%3E%3Cpath d=%27M1.30078 3.83215L4.88738 7.41951L10.9384 1.36853%27 stroke=%27%236E7074%27 stroke-linecap=%27round%27/%3E%3C/svg%3E"), linear-gradient(77deg, $base-color-blue-2 -65.35%, $base-color-light 124.01%);
                    background-repeat: no-repeat;
                    background-position: 50%;
                    border: none;
                }

                .input_title {
                    color: $base-color-grey;
                }
            }
        }
    }

    .lds-spinner div:after {
        background: $base-color-dark;
    }

    .profile_block {
        background-color: #FFF;

        .balance_wrapper .balance {
            color: $base-color-dark-grey;
        }

        .modal_window {
            background-color: #FFF;

            .info_container {
                .name_wrapper .name {
                    color: $base-color-dark-grey;
                }

                .balance_wrapper {
                    background-color: #DDD;

                    .balance {
                        color: $base-color-dark-grey;
                    }
                }
            }

            hr {
                background-color: $base-color-grey;
            }
        }
    }

    .theme_mode_2 {
        background-color: #FFF;
    }

    .theme_mode .switch_block {
        background: $base-color-grey;

        &::after {
            right: 26px;
            background-color: #fff;
        }

        @media (hover: hover) {
            &:hover {
                background: linear-gradient(88deg, $base-color-blue -71.01%, $base-color-light 165.34%), linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);
            }
        }
    }

    .switch-block {
        background-color: #fff;

        &.bckColor2 {
            background-color: $base-color-dark-white;
        }

        .my_button:not(.active) {
            background-color: transparent;
        }
    }

    .my_button.active span {
        color: $base-color-grey;
    }

    .my_button:not(.active) {
        background-color: #FFF;
    }

    .lang_wrapper {
        .my_button {
            background-color: #fff;
        }

        .lang_window {
            background-color: #fff;
        }
    }

    .pagination {
        .arrow {
            background-color: $base-color-dark-white;
        }

        .item.active {
            background-color: $base-color-dark-white;
            color: $base-color-dark;
        }
    }

    .search_block {
        background-color: #fff;

        @media (hover: hover) {
            &:hover input {
                color: $base-color-blue;
            }
        }
    }

    .consultant_wrapper {
        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, #DDD -49.18%, $base-color-blue 176.87%);
    }

    /*--- Menu block desktop ---*/

    .nav_menu {
        .category.active {
            .inner_box {
                svg:not(.filter_btn)>path {
                    fill: $base-color-grey !important;
                }

                .title {
                    color: $base-color-grey !important;
                }
            }

            .filter_btn path {
                stroke: $base-color-grey;
            }
        }

        .content {
            background: linear-gradient(180deg, #FFF 80%, #d4d4d4 100%);
        }

        .help_block {
            background-color: #fff;
        }
    }

    .filter_menu {
        background-color: #F4F4F4;

        .close_filter_menu svg path {
            stroke: $base-color-dark-white;
        }

        .head {
            .title {
                color: $base-color-dark;
            }

            svg path {
                stroke: $base-color-dark-grey;
            }
        }

        .tags_wrapper .input_wrapper {
            .input_title {
                color: $base-color-dark;
            }

            input::after {
                background-color: #DDD;
            }

            input:checked::after {
                background: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%279%27 viewBox=%270 0 12 9%27 fill=%27none%27%3E%3Cpath d=%27M1.30078 3.83215L4.88738 7.41951L10.9384 1.36853%27 stroke=%27%236E7074%27 stroke-linecap=%27round%27/%3E%3C/svg%3E"), linear-gradient(77deg, $base-color-blue-2 -65.35%, $base-color-light 124.01%);
                background-position: 50%;
                background-repeat: no-repeat;
            }
        }
    }

    header {
        background-color: #DDD;
    }

    .coupon {
        background: linear-gradient(180deg, #FFF 80%, #d4d4d4 100%);

        .bet_cart {
            background-color: $base-color-dark-white;

            .title,
            .odd_name,
            .coef,
            .live_wrapper .time span {
                color: $base-color-grey;
            }
        }

        .express_sidebar {
            background: linear-gradient(180deg, #FFF 80%, #d4d4d4 100%);

            .express_coef {
                color: $base-color-dark-grey;
            }
        }

        .history_block {
            &::before {
                background: linear-gradient(267deg, rgba(114, 242, 255, 0) -49.21%, hsla(0, 0%, 36%, 0.3) 176.87%)
            }

            .index {
                background-color: $base-color-dark-white;
                color: $base-color-dark;
            }

            .team {
                color: $base-color-grey;
            }

            .payment {
                color: $base-color-dark-grey;
            }
        }
    }

    .verified_wrapper {
        background-color: #fff;

        .close_modal svg path:last-of-type {
            fill: #FFF;
        }

        .title {
            color: $base-color-dark;
        }
    }

    .bet {
        background-color: #fff;

        .bet_alert {
            svg rect {
                stroke: transparent;
                fill: $base-color-dark-grey;
            }

            svg path {
                stroke: $base-color-dark-grey;
            }

            span {
                color: $base-color-dark-grey;
            }
        } 

        .edit_bet_wrapper {
            background-color: #EBEBEB;
        }

        .bet_summa {
            .input_block {
                background-color: $base-color-dark-white;

                span {
                    color: #313439;
                }
            }

            @media (hover: hover) {
                &:hover {
                    .input_block {
                        border: 1px solid $base-color-grey-2;

                        input {
                            color: $base-color-dark;
                        }
                    }
                }
            }
        }

        .coef_block .input_block .input_wrapper {
            input::after {
                background-color: $base-color-dark-white;
            }

            input:checked::after {
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'%3E%3Cpath d='M1.30078 3.83215L4.88738 7.41951L10.9384 1.36853' stroke='%236E7074' stroke-linecap='round'/%3E%3C/svg%3E"), linear-gradient(77deg, $base-color-blue-2 -65.35%, $base-color-light 124.01%);
                background-repeat: no-repeat;
                background-position: 50%;
            }

            .input_title {
                color: $base-color-grey;
            }
        }
    }

    /*--- Menu block tablet / mobile ---*/

    .mobile_fixed_window {
        .logo_wrapper {
            background-color: #fff;

            .theme_mode .switch_block::after {
                right: 18px;
            }
        }

        .auth_wrapper>.my_button {
            background-color: #fff;
        }

        .auth_wrapper.open>.my_button {
            background-color: #3AC6D4;
        }

        .mobile_btn {
            background-color: #FFF;
        }

        .category_container {
            background-color: #fff;
        }
    }

    .menu_container {
        background-color: #DDD;

        &.menu_active {
            background-color: transparent;

            .menu {
                background-color: #FFF;
                box-shadow: 0px 4px 44px rgba(39, 39, 40, 0.13);
            }
        }
    }

    .tablet_menu_wrapper .my_button {
        background-color: #fff;
    }

    .mobile_menu_wrapper .mobile_menu {
        background-color: #fff;
    }

    .top-sidebar {
        background-color: #DDD;
    }

    .sidebar {
        background-color: #fff;

        &.mobile_sidebar {
            .search_block {
                background-color: #DDD;
            }

            .menu .item_wrapper {
                border-bottom-color: #DDD;
            }
        }

        .menu .item_wrapper .item_submenu .category.active .inner_box {
            svg path {
                fill: $base-color-grey !important;
            }

            .title {
                color: $base-color-grey !important;
            }
        }
    }

    /*--- Main blocks ---*/

    footer {
        background-color: #fff;

        .item_container .title {
            color: $base-color-dark;
        }
    }

    /*--- Another blocks ---*/

    .main_slider {
        .slide {
            border: 3px solid #FFF;

            .text_wrapper {
                color: $base-color-dark;
            }

            .read_more {
                span {
                    color: $base-color-dark;
                }

                svg path {
                    fill: $base-color-dark;
                }
            }
        }

        .slick-arrow svg path {
            stroke: $base-color-dark;
        }

        .slick-dots li {
            background: $base-color-dark;
        }
    }

    .slider_wrapper .category_slider {
        .slick-arrow {
            background: #fff;

            svg path {
                stroke: $base-color-dark;
            }
        }
    }

    .category_card {
        background: #FFF;

        .card_name {
            background-color: $base-color-dark-white;
        }

        .main_info .team .team_name {
            color: $base-color-dark;
        }

        .main_info .date_wrapper .time {
            color: $base-color-dark-grey;
        }

        .coef_wrapper .coef_block {
            &.active .title {
                color: $base-color-dark;
            }

            @media (hover: hover) {
                &:hover .title {
                    color: $base-color-dark;
                }
            }

            .coef {
                color: $base-color-dark;
            }
        }
    }

    .breadcrumb {
        .page.current_page {
            color: $base-color-dark;
        }
    }

    .no-events_wrapper {
        background-color: #FFF;

        .no-events .title {
            color: $base-color-dark-grey;
        }
    }

    .modal_wrapper {
        background-color: hsla(0, 0%, 100%, 0.9);

        .modal {
            background-color: #FFF;

            .close_modal svg path:last-of-type {
                fill: #FFF;
            }

            .modal-content {
                .title {
                    color: $base-color-dark;
                }

                .switch-block {
                    background-color: hsla(220, 3%, 44%, 0.2);

                    .my_button span {
                        color: $base-color-dark;
                    }
                }
            }
        }
    }

    /*--- Login pages ---*/

    .error_wrapper ul li {
        color: $base-color-dark-grey;
    }

    .sign-in-methods .method {
        background-color: $base-color-dark-white;

        svg path {
            fill: $base-color-grey;
        }
    }

    .sign_form .content {
        background-color: #fff;

        h1 {
            color: $base-color-dark-grey;
        }

        form .fieldset {
            @media (hover: hover) {
                &:hover input {
                    color: $base-color-blue;
                }
            }

            label {
                color: $base-color-dark-grey;
            }

            input {
                background-color: #F4F4F4;
            }


        }

        .or span {
            background: #fff;
        }

        .sign-in-block {
            color: $base-color-grey;

            a {
                color: $base-color-grey;
            }

            label {
                input::after {
                    background-color: $base-color-dark-white;
                }

                input:checked::after {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'%3E%3Cpath d='M1.30078 3.83215L4.88738 7.41951L10.9384 1.36853' stroke='%236E7074' stroke-linecap='round'/%3E%3C/svg%3E"), linear-gradient(77deg, $base-color-blue-2 -65.35%, $base-color-light 124.01%);
                    background-repeat: no-repeat;
                    background-position: 50%;
                }
            }
        }
    }

    /*--- Category page ---*/

    .content_block_wrapper.small_width .page_title {
        color: $base-color-grey;
    }

    .match_list .list_title {
        color: $base-color-grey;
    }

    .match {
        background-color: #FFF;

        .team_block .team .team_name {
            color: $base-color-grey;
        }

        .live_grid .col_item {
            color: $base-color-grey;

            &.col_title {
                color: $base-color-dark;
            }
        }
    }

    .coef_box {
        &:not(.T-block) {
            &.active .coefTitle {
                color: $base-color-grey;
            }

            @media (hover: hover) {
                &:hover .coefTitle {
                    color: $base-color-grey;
                }
            }
        }

        .value {
            color: $base-color-dark;
        }
    }

    .mobile_filter_btn {
        background: $base-color-dark-white;

        @media (hover: hover) {
            &:hover {
                background: $base-color-blue;

                svg rect {
                    fill: $base-color-dark;
                }

                .title {
                    color: $base-color-dark;
                }
            }
        }
    }

    /*--- Match pages ---*/

    .match_header {
        background-color: #fff;

        .live_block {
            background-color: rgba(255, 255, 255, 0.6);

            .team_block .team .team_name {
                color: $base-color-dark;
            }

            .live_grid .col_item {
                color: $base-color-grey;

                &.col_title {
                    color: $base-color-dark;
                }
            }
        }

        .img_background {
            opacity: .25;
        }

        .team_wrapper {
            .team .team_name {
                color: $base-color-dark-grey;
            }

            .coef {
                color: $base-color-dark-grey;
            }
        }

        .block_info {
            color: $base-color-dark;

            .category {
                opacity: 1;
            }

            .title {
                color: $base-color-grey-2;
                opacity: 1;
            }
        }
    }

    .tab_menu {
        background-color: #fff;

        .tab {
            background-color: #FFF;

            &.active {
                color: $base-color-dark-grey;
            }
        }
    }

    .bets_container {
        background-color: #FFF;

        .item_wrapper {
            .item .title_box {
                .modal {
                    box-shadow: 0px 4px 24px rgb(0 0 0 / 26%);
                    background-color: #FFF;

                    .title {
                        color: $base-color-dark-grey;
                    }

                    .subtitle {
                        color: $base-color-grey;
                    }
                }

                .item_title {
                    color: $base-color-dark-grey;

                }
            }

            .item_submenu .bet-coef_wrapper {
                .bet-coef {
                    @media (hover: hover) {
                        &:hover .title {
                            color: $base-color-blue;
                        }
                    }
                }

                .total_block {
                    color: $base-color-dark-grey;
                }
            }
        }
    }

    .bet-coef .coef {
        color: $base-color-dark-grey;
    }

    /*--- Profile page ----*/

    .profile_info {
        .info_container {
            background-color: #FFF;

            .name {
                color: $base-color-dark;
            }
        }
    }

    .card_list .card_item {
        background-color: #FFF;

        .img_wrapper {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDECEC 100%);
        }

        .card_name {
            color: $base-color-dark;
        }
    }

    .documents_card_list .card_item {
        background-color: $base-color-dark-white;

        .img_wrapper {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
        }

        .card_name {
            color: $base-color-dark;
        }
    }

    .profile_content_block {
        background-color: #FFF;
    }

    /*--- ProfilePageTitle ----*/

    .profile_page_title {
        color: $base-color-dark;
    }

    /*--- Help Info page ----*/

    .text-info_page .content_block .inner_block a {
        color: $base-color-dark;
    }

    .support_service {
        .block_name {
            color: $base-color-dark;
        }

        .service_list .service {
            background: linear-gradient(90deg, $base-color-dark -1.16%, rgba(40, 41, 46, 0.00) 112.17%);

            @media (hover: hover) {
                &:hover {
                    background: linear-gradient(90deg, $base-color-blue-2 -1.16%, rgba(255, 255, 255, 0.00) 112.17%);

                    svg path {
                        fill: $base-color-dark-grey;
                    }

                    .title {
                        color: $base-color-dark-grey;
                    }
                }
            }

            svg path {
                fill: #FFF;
            }

            .title {
                color: #FFF;
            }
        }
    }

    /*--- Balance page ----*/

    .tab_menu-balance,
    .tab_menu-personal-info {
        background-color: #fff;

        .tab {
            background-color: #FFF;

            &.active {
                color: $base-color-dark-grey;
            }
        }
    }

    .balance_form .bet_summa {
        .input_title {
            color: $base-color-dark;
        }

        .input_block {
            background-color: $base-color-dark-white;

            @media (hover: hover) {
                &:hover input {
                    color: $base-color-blue;
                }
            }
        }
    }

    .balance_page .inner_block {
        .form_wrapper .text_info .title {
            color: $base-color-dark;
        }

        .balance_alert_wrapper .balance_alert {
            svg:not(.remove) {
                path {
                    stroke: $base-color-dark;
                }

                rect {
                    stroke: $base-color-dark;
                    fill: $base-color-dark;
                }
            }

            .title {
                color: $base-color-dark;
            }

            .subtitle {
                color: $base-color-dark-grey;
            }

            &.unsuccess svg.remove {

                path,
                rect {
                    fill: $base-color-dark;
                }
            }
        }
    }

    .sort_wrapper h2 {
        color: $base-color-dark;
    }

    .table_data {
        background-color: #FFF;

        .state,
        .date,
        .sum,
        .id,
        .col {
            span {
                color: $base-color-dark;
            }
        }
    }

    /*--- Personal Info page ----*/

    .form_container {
        background-color: #FFF;

        &>.title {
            color: $base-color-dark;
        }

        form .col_labels .input_wrapper {
            .input_text {
                color: $base-color-dark;
            }

            input {
                background-color: $base-color-dark-white;
                border-color: $base-color-grey-2;
                color: $base-color-dark;

                &::-webkit-calendar-picker-indicator {
                    background-image: revert;
                }
            }
        }
    }

    /*--- Profile documet upload page ----*/

    .document_upload_page .content_block {
        .img_wrapper {
            background-color: $base-color-dark-white;
        }

        .inner_block {
            .title {
                color: $base-color-dark;
            }
        }
    }

    .button_wrapper .my_button:first-of-type {
        background: linear-gradient(90deg, $base-color-dark -1.16%, rgba(40, 41, 46, 0.00) 112.17%);

        span {
            color: #FFF;
        }
    }

    .images_upload_list .image_upload {
        .file_info {
            .file_name, .file_size {
                color: $base-color-dark;
            }
        }

        .icon_container .icon {
            background-color: $base-color-dark-white;
        }
    }
}