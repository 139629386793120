@import "styles/common_styles/index";

.nav_menu {
    position: fixed;
    border-radius: 20px;
    width: $nav-menu-width;
    min-height: calc(100vh - 56px);
    // min-height: 95vh;
    max-height: 95vh;
    z-index: 101;

    .content {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: 1;
        overflow: hidden;
        background: linear-gradient(180deg, $base-color-dark-grey 80%, #272728 100%);

        .help {
            padding: 0 40px;
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            opacity: .4;
            margin-bottom: 4px;
        }

        .category_wrapper {
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #464649;
                border-radius: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: #8f9196;
                border-radius: 8px;
            }
        }
    }

    .help_block {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        z-index: 1;
        height: 112px;
        background-color: $base-color-dark-grey;
        padding: 20px 40px 34px;

        .help {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 8px;
        }

        .help-info {
            @media (hover: hover) {
                &:hover {
                    svg path {
                        fill: $base-color-blue;
                    }

                    .title {
                        background: linear-gradient(88deg, $base-color-blue -71.01%, $base-color-light 165.34%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }

            .title {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                margin-left: 8px;
            }
        }
    }
}