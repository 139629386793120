@import "styles/common_styles/index";

.button_wrapper {
    height: 58px;

    .my_button.is-blocked {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }

    .my_button:first-of-type {
        background: linear-gradient(90deg, #FFF -1.16%, rgba(255, 255, 255, 0.00) 112.17%);
        margin-right: 14px;

        @media (hover: hover) {
            &:hover {
                background: linear-gradient(88deg, $base-color-blue -1.56%, #72F2FF 127.21%), linear-gradient(267deg, rgba(0, 200, 220, 0) -49.21%, rgba(58, 198, 212, 0.3) 176.87%) !important;
            }
        }
    }
}

.errors_block {
    margin-top: 22px;

    span {
        display: flex;
        align-items: center;
        border-radius: 15px;
        background: linear-gradient(89deg, $base-color-red -185.19%, rgba(249, 57, 57, 0.98) -178.62%, rgba(249, 57, 57, 0.00) 130.19%);
        padding: 16px 20px;

        color: #FFF;
        font-family: Nunito sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
    }
}

.loader_wrapper {
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .lds-spinner {
        transform: scale(.75);
    }
}

.images_upload_list {
    width: 100%;
    display: flex;
    margin-top: 22px;
    flex-wrap: wrap;

    .image_upload {
        display: flex;
        max-width: 47%;
        width: 100%;
        min-width: 220px;
        margin-bottom: 12px;
        margin-right: 12px;

        .icon_container {
            position: relative;
            max-width: 100px;
            width: 100%;
            height: 120px;
            background: $base-color-dark-grey-2;
            border-radius: 9px;

            .close_icon {
                box-shadow: 0 0 10px rgba(0, 0, 0, .3);
                position: absolute;
                left: 0;
                top: 0;
                background-color: #FFF;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                transform: translate(-30%, -30%);
                cursor: pointer;

                @media (hover: hover) {
                    &:hover {
                        background-color: $base-color-blue;
                    }
                }

                svg {
                    max-width: 8px;
                    max-height: 8px;
                    max-width: 8px;
                    max-height: 8px;

                    path,
                    rect {
                        stroke: #000;
                    }
                }
            }

            .icon {
                border-radius: 9px;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }

            img {
                height: fit-content;
                max-height: 100%;
                border-radius: 9px;
                object-fit: contain;
            }
        }

        .file_info {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            .file_name,
            .file_size {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                word-break: break-all;
            }
        }

        .confirmed_wrapper {
            display: flex;
            margin-top: 4px;
            margin-left: 6px;

            svg {
                min-width: 22px;
                min-height: 15px;

                path,
                rect {
                    stroke: $base-color-blue;
                }
            }
        }
    }
}

@media screen and (max-width: $xl-size-max-width) {
    .images_upload_list .image_upload {
        max-width: 100%;
    }
}

@media screen and (max-width: $lg-size-max-width) {
    .images_upload_list .image_upload {
        max-width: 47%;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .images_upload_list .image_upload {
        max-width: 100%;
    }

    .errors_block span {
        font-size: 15px;
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .button_wrapper {
        height: fit-content;
        flex-direction: column;

        .my_button:first-of-type {
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }

    .images_upload_list {
        flex-direction: column;
    }
}