@import "styles/common_styles/index";

.help_info_page .content_block {
    margin-top: 32px;
    padding: 44px 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: $sm-size-max-width) {
    .help_info_page .content_block {
        padding: 25px 0 40px;
    }
}