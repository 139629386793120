@import "styles/common_styles/index";

.match_header {
    border-radius: 13px;
    background-color: $base-color-dark-grey;
    position: relative;
    overflow: hidden;
    margin-top: 32px;
    margin-bottom: 30px;
    padding: 24px 32px;

    &.live {
        padding: 0;
    }

    .match_content {
        padding: 24px 32px;
    }

    .img_background {
        position: absolute;
        filter: blur(6.5px);
        opacity: 0.15;
        height: 150%;
        max-width: 48%;
        object-fit: contain;
        max-height: 100%;
        top: 0;

        &.team_1 {
            left: 0;
        }

        &.team_2 {
            right: 0;
        }
    }

    .team_wrapper {
        max-width: 30%;
        width: 100%;
        z-index: 1;
        flex-grow: 1;
        justify-content: space-between;
        flex-wrap: wrap;

        .team {
            img {
                max-width: 64px;
                max-height: 64px;
            }

            .team_name {
                margin: 0 12px;
                color: #FFF;
                text-align: center;
                font-family: Nunito Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        .coef {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
            flex-grow: 1;
            text-align: center;
        }
    }

    .block_info {
        z-index: 1;
        flex-grow: 1;
        font-family: Nunito Sans;
        font-style: normal;
        line-height: 140%;
        text-align: center;
        color: #FFF;

        .category {
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 8px;
            opacity: .4;

            &.live {
                margin-bottom: 0;
            }
        }

        .live {
            display: flex;
            align-items: center;

            svg path {
                fill: $base-color-red;
            }

            span {
                margin-left: 6px;
                color: $base-color-red;
                font-family: Nunito Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
            }
        }

        .date {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 4px;
        }

        .time {
            text-align: initial;
            font-size: 36.776px;
            font-weight: 700;
            line-height: 100%;
            margin-bottom: 8px;
        }

        .title {
            opacity: .4;
            color: $base-color-dark-white;
            font-size: 14px;
            font-weight: 300;
        }
    }

    .live_block {
        padding: 24px 32px;
        z-index: 1;
        border-radius: 13px;
        background: rgba(40, 41, 46, 0.6);

        .team_block {
            padding-right: 32px;
            flex-grow: 1;

            .live_match {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                margin-bottom: 8px;
            }

            .team {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                img {
                    max-width: 20px;
                    max-height: 20px;
                    margin-right: 12px;
                }

                .team_name {
                    color: #FFF;
                    font-family: Nunito Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                }
            }
        }

        .live_grid {
            margin-right: 0;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .match_header {
        margin-top: 20px;

        .img_background {
            height: 90%;
        }

        .block_info {
            .category {
                font-size: 12px;
                margin-bottom: 12px;
            }

            .date {
                font-size: 12px;
            }

            .time {
                font-size: 30px;
                margin-bottom: 12px;
            }

            .title {
                font-size: 12px;
            }
        }

        .team_wrapper {
            .team .team_name {
                font-size: 11px;
                margin-top: 6px;
            }

            .coef {
                font-size: 15px;
                margin-top: 12px;
            }
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .match_header {
        padding: 20px;

        .img_background {
            height: 65%;
        }
    }
}