@import "styles/common_styles/index";

.profile_block {
    background-color: $base-color-dark-grey;
    border-radius: 20px;
    max-width: 280px;
    min-width: 280px;
    width: 100%;
    // margin-left: 32px;
    margin-left: 10px;
    padding-left: 14px;

    &.open {
        .modal_window {
            opacity: 1;
            max-height: 500px;
            padding-top: 16px;
        }
    }

    .modal_window {
        top: 60px;
        width: 100%;
        opacity: 0;
        max-height: 0px;
        padding-top: 0;
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        background-color: $base-color-dark-grey;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.60);
        border-radius: 20px;
        transition:
            opacity .5s ease-in-out,
            max-height .5s ease-in-out,
            padding .5s ease-in-out;

        .info_container {
            padding: 0 20px 16px;

            .avatar_image {
                max-width: 48px;
                max-height: 48px;
                border-radius: 50%;
                margin-right: 8px;
            }

            svg {
                min-width: 48px;
                min-height: 48px;
                margin-right: 8px;
            }

            .name_wrapper {
                margin-right: 4px;
                max-width: 100px;
                width: 100%;

                .name {
                    color: #FFF;
                    font-family: Nunito Sans;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    margin-bottom: 4px;

                    max-width: inherit;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .user_id {
                    color: $base-color-grey;
                    font-family: Nunito Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;

                    max-width: inherit;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            .balance_wrapper {
                min-width: 80px;
                max-width: 100px;
                width: 100%;
                margin-left: auto;
                padding: 12px 8px;
                border-radius: 14px;
                background-color: $base-color-dark-grey-2;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        hr {
            border: none;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $base-color-dark-grey-2;
        }

        .profile_items {
            padding: 18px 20px;

            .item {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                letter-spacing: 0.43px;
                margin-bottom: 16px;

                @media (hover: hover) {
                    &:hover {
                        color: $base-color-blue;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .my_button {
            border-radius: 0;

            span {
                align-items: center;
                display: flex;
                line-height: initial;

                svg {
                    margin-left: 8px;
                    margin-right: 0;
                }
            }
        }
    }

    .avatar {
        margin-right: 20px;

        .avatar_image {
            max-width: 38px;
            max-height: 38px;
            border-radius: 50%;
        }

        svg {
            min-width: 38px;
            min-height: 38px;
        }
    }

    .balance_wrapper {
        padding-right: 4px;
        flex-grow: 1;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 700;

        .title {
            color: $base-color-grey;
            font-size: 11px;
            line-height: 100%;
        }

        .balance {
            color: #FFF;
            font-size: 14px;
            line-height: 110%;
            margin-top: 3px;
            max-width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .profile_block {
        padding: 0 8px;
        margin-left: 0;
        max-width: fit-content;
        min-width: initial;
        position: initial !important;

        .modal_window {
            width: 280px;
            right: 12px;
            left: initial;
            top: 70px;
            transform: initial;
        }

        .avatar {
            margin-right: 0;
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .profile_block .modal_window {
        width: calc(100vw - 26px);
        top: 60px;
    }
}