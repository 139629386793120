@import "styles/common_styles/index";

.aside_menu {
    top: 30px;
    right: 30px;
    position: fixed;
    width: $aside-menu-width;
    min-height: calc(100vh - 56px);
    // min-height: 95vh;
    max-height: 95vh;

    .coupon .express_sidebar {
        top: 132px;
    }

    .login_lang {
        visibility: hidden;
    }

    .content_wrapper {
        border-radius: 20px;
        width: 100%;
        height: 100%;
        margin-top: 18px;
        overflow: hidden;
    }
}

@media screen and (max-width: $lg-size-max-width) {
    .aside_menu {
        right: 24px;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .aside_menu {
        right: 13px;
    }
}