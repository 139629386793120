@import "styles/common_styles/index";

.mobile_menu_wrapper {
    background-color: $base-color-dark;
    padding: 10px 13px;
    position: fixed;
    width: 100%;
    z-index: 110;
    left: 0;
    bottom: 0;

    .mobile_menu {
        width: 100%;
        padding: 10px 12px 8px;
        background-color: $base-color-dark-grey;
        border-radius: 13px;
        justify-content: space-evenly;
        align-items: center;

        .item {
            user-select: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 8px;
            cursor: pointer;
            width: 20%;

            &.active {
                .burger {
                    i,
                    &::before,
                    &::after {
                        height: 3px;
                        background-color: $base-color-blue;
                    }

                    i {
                        width: 0;
                        opacity: 0;
                    }

                    &::before {
                        top: 10px;
                        transform: rotate(-135deg);
                    }

                    &::after {
                        bottom: 7px;
                        transform: rotate(315deg);
                    }
                }

                svg path {
                    fill: $base-color-blue;
                }

                span {
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-dark -49.18%, $base-color-blue 16.87%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .burger {
                height: 20px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    transition: .8s all ease-in-out;
                }

                i {
                    opacity: 1;
                    transition: .6s all ease-in;
                }

                i,
                &::before,
                &::after {
                    border-radius: 20px;
                    width: 22px;
                    height: 4px;
                    display: block;
                    background-color: $base-color-grey;
                }

                &::before {
                    top: 0;
                }

                &::after {
                    bottom: 0;
                }
            }

            svg {
                max-width: 28px;
                width: 100%;
                max-height: 25px;
                height: 100%;

                path {
                    fill: $base-color-grey;
                }
            }

            span {
                margin-top: 4px;
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 11px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
}