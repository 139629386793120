@import "styles/common_styles/index";


.link_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .link {
        padding: 20px 40px;
        border-bottom: 1px solid $base-color-grey;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (hover: hover) {
            &:hover {
                svg path {
                    stroke: $base-color-blue;
                }

                .title {
                    color: $base-color-blue;
                }
            }
        }

        &:last-of-type {
            border-bottom: none;
        }

        .title {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .link_block .link .title {
        font-size: 18px;
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .link_block {
        margin-bottom: 22px;

        .link {
            padding: 16px 20px;
    
            .title {
                font-size: 16px;
            }
        }
    } 
}