@import "styles/common_styles/index";

.profile_info {
    margin-bottom: 16px;

    .info_container {
        flex-grow: 1;
        background-color: $base-color-dark-grey;
        border-radius: 20px;
        padding: 8px 12px;

        .avatar_image {
            border-radius: 50%;
            max-width: 48px;
            max-height: 48px;
            margin-right: 14px;
        }

        svg {
            min-width: 48px;
            min-height: 48px;
            margin-right: 14px;
        }

        .name_wrapper {
            margin-right: 6px;
            max-width: 200px;

            .name {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                margin-bottom: 6px;
            }

            .user_id {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;

                max-width: inherit;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .balance_wrapper {
            margin-left: auto;
            align-items: center;

            .title {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                margin-right: 8px;
            }

            .balance {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
            }
        }
    }

    .switch-block {
        flex-grow: 1;
        margin-left: 14px !important;

        .sign_out span {
            display: flex;
        }
    } 
}

@media screen and (max-width: $sm-size-max-width) {
    .profile_info {
        .info_container {
            margin-bottom: 14px;
        }

        .switch-block {
            margin-left: 0 !important; 
        }
    } 
}

@media screen and (max-width: $xs-size-max-width) {
    .profile_info .info_container .balance_wrapper {
        flex-direction: column;

        .title {
            margin-right: 0;
            margin-bottom: 4px;
        }
    }
}