@import "common_styles/index";
@import "common_styles/initial";
@import "common_styles/initial_specified_media";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import './LightTheme.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
}

body::-webkit-scrollbar {
    width: 8px;
    background-color: $base-color-dark;
}

body::-webkit-scrollbar-thumb {
    background-color: #464649;
    border-radius: 8px;
}

body::-webkit-scrollbar-track {
    background-color: #8f9196;
    border-radius: 8px;
}

#root {
    background-color: $base-color-dark;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 28px 30px;
}


.App {
    position: relative;

    .slick-slider {
        .slick-arrow::before {
            content: '';
        }
    }
}

.content_block_wrapper {
    padding-left: 30px;
    padding-top: 70px;
    width: calc(100% - ($nav-menu-width));
    margin-left: $nav-menu-width;

    &.small_width {
        width: calc(100% - ($nav-menu-width + $aside-menu-width));
        padding: 0 32px;
        padding-top: 70px;

        .content_block {
            position: relative;
            z-index: 1;
        }
    }

    &>.breadcrumb {
        margin-top: 12px
    }
}

main:not(.sign_form) {
    min-height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: $lg-size-max-width) {
    #root {
        padding: 16px 24px;
    }

    .content_block_wrapper,
    .content_block_wrapper.small_width {
        padding: 140px 0 0;
        width: 100%;
        margin-left: initial;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    #root {
        padding: 10px 13px 16px;
    }

    .content_block_wrapper>.breadcrumb {
        margin-top: 0;
    }

    .content_block_wrapper,
    .content_block_wrapper.small_width {
        padding: 140px 0 0;
    }

    main:not(.sign_form) {
        min-height: calc(100vh - 36px);
    }
}


.blackout {
    width: 100vw;
    height: 100vh;
    background: #000;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    transition: .8s opacity ease-in-out;

    &.active {
        z-index: 107 !important;
        opacity: .5 !important;
    }

    &.active-top {
        z-index: 104;
        opacity: 0;
    }
}

.sidebar {
    background-color: $base-color-dark;
    height: 100%;
    max-width: 324px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 18px 14px;
    padding-bottom: 92px;
    position: fixed;
    right: -100%;
    top: 0;
    transition: right .6s;
    width: 100%;
    z-index: 108;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: $base-color-dark;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #464649;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: #8f9196;
        border-radius: 8px;
    }

    &.active {
        right: 0;
        transition: right .6s ease .2s;
    }

    .sidebar_button {
        cursor: pointer;

        @media (hover: hover) {
            &:hover path {
                fill: $base-color-blue;
            }
        }
    }
}

.top-sidebar {
    background-color: $base-color-dark;
    height: calc(100% - 140px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px 13px 92px;
    position: fixed;
    left: 0;
    top: -100%;
    transition: right .6s;
    z-index: 105;
    transition: top .6s ease .2s;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: $base-color-dark;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #464649;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: #8f9196;
        border-radius: 8px;
    }

    &.active {
        top: 140px;
    }
}

.menu_container {
    &.menu_active {
        transition: .8s all ease-in-out;
        background-color: transparent;
        padding-top: 0;

        .menu {
            transition: .8s box-shadow ease-in-out;
            box-shadow: 0px 4px 44px rgba(39, 39, 40, 0.8);
        }
    }
}




.modal_wrapper {
    background-color: hsla(230, 7%, 17%, 0.9);
    width: 100vw;
    height: 100vh;
    // max-height: calc(100vh - 138px);
    // height: 100%;
    z-index: 110;
    left: 0;
    top: 0;
    padding: 28px 30px;

    .modal {
        max-width: 800px;
        width: 100%;
        max-height: 100%;
        overflow: hidden;
        background-color: #313439;
        border-radius: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 80px;

        .close_modal {
            position: absolute;
            right: 10px;
            top: 10px;

            @media (hover: hover) {
                &:hover {
                    svg path:first-of-type {
                        fill: $base-color-blue;
                    }
                }
            }
        }

        .modal-content {
            max-width: 90%;
            width: 100%;
            margin: 0 auto;

            .title {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 35.747px;
                font-style: normal;
                font-weight: 800;
                line-height: 34.198px;
                margin-bottom: 16px;
                word-break: break-word;
            }

            .subtitle {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 17px;
                font-style: normal;
                font-weight: 500;
                line-height: 22.715px;
                margin-bottom: 16px;
            }

            .switch-block {
                background-color: $base-color-grey;

                .my_button.active {
                    width: 150%;
                }

                .my_button:not(.active) span {
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .modal_wrapper {
        padding: 28px 24px;

        .modal {
            padding: 80px 52px;

            .modal-content .title {
                font-size: 30px;
            }
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .modal_wrapper {
        .modal {
            padding: 60px 40px;
        }
    }
}



.bet,
.coupon {
    .block_title {
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 19px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        display: block;
        text-align: center;

        svg {
            transition: .3s all ease-in-out;
            transform: rotate(180deg);
            cursor: pointer;
            margin-left: 6px;
        }
    }
}

.bet .block_title {
    @media (hover: hover) {
        &:last-of-type:hover {
            background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 96.87%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            svg path {
                stroke: $base-color-blue;
            }
        }
    }
}





.profile_content_block {
    background-color: $base-color-dark-grey;
    border-radius: 20px;
}