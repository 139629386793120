$base-color-blue: #3AC6D4;
$base-color-blue-2: #209CA9;

// $base-color-grey: #a2a2a2;
$base-color-grey: #6E7074;
$base-color-grey-2: #b1b1b1;
$base-color-dark: #28292E;

$base-color-dark-grey: #313439;
$base-color-dark-grey-2: #3D3F44;


$base-color-red: #F93939;
$base-color-dark-white: #EBEBEB;
$base-color-light: #72F2FF;



$nav-menu-width: 288px;
$aside-menu-width: 280px;