@import "styles/common_styles/index";

.bet_cart {
    background-color: rgba(110, 112, 116, 0.2);
    padding: 10px 14px 20px 18px;
    border-radius: 20px;
    margin-bottom: 18px;
    overflow: hidden;

    .title {
        color: #fff;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    svg.remove {
        cursor: pointer;
        margin-left: 8px;

        @media (hover: hover) {
            &:hover {
                path, rect {
                    fill: $base-color-blue;
                }
            }
        }
    }

    hr {
        display: block;
        height: 2px;
        background-color: $base-color-grey;
        border: none;
        width: 130%;
        margin-left: -30px;
        margin-top: 12px;
        margin-bottom: 10px;
    }

    .live_wrapper {
        .live {
            border-radius: 6px;
            background: $base-color-red;
            padding: 6px;

            svg {
                width: 15px;
                margin-right: 2px;
            }

            span {
                color: #F4F4F4;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }
        }

        .time {
            svg {
                width: 15px;
                margin-right: 4px;
            }

            span {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }
    }

    .subtitle {
        margin-top: 8px;
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .odd_name,
    .coef {
        color: #FFF;
        font-family: Nunito Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        max-width: 180px;
    }

    .coef {
        font-size: 24px;

        &.rais {
            color: $base-color-blue;
        }

        &.fall {
            color: $base-color-red;
        }
    }
}