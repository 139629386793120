@import "styles/common_styles/index";

.category {
    position: relative;
    display: flex;
    align-items: center;

    &.active {
        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);

        .inner_box {
            svg:not(.filter_btn)>path {
                fill: #FFF !important;
            }

            .title {
                color: #FFF !important;
            }
        }

        .filter_btn path {
            stroke: #FFF;
        }
    }

    .inner_box {
        display: flex;
        align-items: center;
        padding: 16px 0 16px 40px;
        width: 100%;

        svg:not(.filter_btn) {
            margin-right: 12px;
        }

        .title {
            width: min-content;
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .quantity {
            margin-right: 15px;
            margin-left: 4px;
            padding: 2px 6px;
            background-color: $base-color-blue;
            border-radius: 65px;

            color: $base-color-dark-grey;
            font-family: Nunito;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .filter_btn {
        margin-right: 15px;
        transition: .2s opacity ease-in-out;
        opacity: 0;
        visibility: hidden;
        min-height: 22px;
        min-width: 14px;
        margin-left: auto;
        user-select: none;

        @media (hover: hover) {
            &:hover {
                path {
                    stroke: $base-color-blue !important;
                }
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            .inner_box {
                svg:not(.filter_btn)>path {
                    fill: $base-color-blue;
                }

                .title {
                    color: $base-color-blue;
                }
            }

            .filter_btn {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}