@import "styles/common_styles/index";

.bets_container {
    border-radius: 13px;
    background-color: $base-color-dark-grey;
    box-shadow: 0px 4px 31px 0px rgba(0, 0, 0, 0.08);

    .item_wrapper {
        width: 100%;
        border-bottom: 1px solid $base-color-grey;
        padding: 24px 32px;
        user-select: none;

        @media (hover: hover) {
            &:hover {
                .item {
                    .title_box {
                        .item_title {
                            background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, #3AC6D4 16.87%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        &>svg {
                            path:first-of-type {
                                stroke: $base-color-blue;
                            }

                            path:last-of-type {
                                fill: $base-color-blue;
                            }
                        }
                    }
                }
            }
        }

        &:last-of-type {
            border-bottom: none;
        }

        &.active {
            .item>svg {
                transform: rotate(180deg) translateX(-4px);
                cursor: pointer;
            }

            .item_submenu {
                padding-top: 24px;
                max-height: 2000px;
            }
        }

        .item {
            position: relative;

            .title_box {

                .modal {
                    z-index: 10;
                    bottom: 26px;
                    visibility: hidden;
                    opacity: 0;
                    max-width: 445px;
                    width: 100%;
                    position: absolute;
                    border-radius: 13px;
                    background-color: $base-color-dark-grey;
                    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.60);
                    padding: 16px 22px 32px 32px;
                    transition: .4s all ease-in-out;

                    &.active {
                        visibility: visible;
                        opacity: 1;
                    }

                    svg {
                        margin-left: auto;
                        margin-bottom: 4px;
                    }

                    .title {
                        margin-bottom: 10px;
                        color: #FFF;
                        font-family: Nunito Sans;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 110%;
                    }

                    .subtitle {
                        color: #DDD;
                        font-family: Nunito Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 122%;
                    }
                }

                .item_title {
                    color: #FFF;
                    font-family: Nunito Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                }

                svg {
                    margin-right: 4px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }

            &>svg {
                padding-left: 4px;

                @media (hover: hover) {
                    &:hover path {
                        stroke: $base-color-blue;
                    }
                }
            }
        }

        .item_submenu {
            padding-top: 0;
            max-height: 0;
            overflow: hidden;
            width: 100%;

            .bet-coef_wrapper {
                margin-bottom: 16px;

                .bet-coef {
                    &.active:not(.is_stoped) {
                        .title {
                            color: $base-color-blue;
                        }

                        .coef {
                            color: $base-color-blue;
                        }
                    }

                    @media (hover: hover) {
                        &:not(.is_stoped):hover {
                            cursor: pointer;

                            .title {
                                color: $base-color-blue;
                            }

                            .coef {
                                color: $base-color-blue;
                            }
                        }
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                .total_block {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    background-color: #6e70743e;
                    border-radius: 13px;
                    overflow: hidden;
                    padding: 10px;
                    color: #FFF;
                    text-align: center;
                    font-family: Nunito Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 100%;
                }

                .cap {
                    width: 100%;
                    padding: 10px 0 10px 0;
                    margin-left: 32px;
                    order: 1;

                    &.under {
                        order: initial;
                        padding-right: 32px;
                        border-right: 1px solid #6E7074;

                        &+.bet-coef {
                            padding-right: 0;
                            border-right: none;
                        }
                    }

                    &+.bet-coef {
                        padding-right: 32px;
                        border-right: 1px solid #6E7074;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: $sm-size-max-width) {
    .bets_container .item_wrapper {
        padding: 24px 18px;

        .item_submenu .bet-coef_wrapper .cap {
            margin-left: 8px;

            &.under {
                padding-right: 8px;
            }

            &+.bet-coef {
                padding-right: 8px;
            }
        }

        &.active .item_submenu {
            padding-top: 18px;
        }

        .item .title_box .modal {
            max-width: 380px;
            padding: 14px 18px 18px 22px;

            .title {
                font-size: 14px;
            }

            .subtitle {
                font-size: 12px;
            }
        }
    }
}