@import "styles/common_styles/index";

.card_list {
    margin-top: 18px;
    display: grid;
    grid-gap: 18px;
    grid-gap: 18px;
    grid-template-columns: repeat(4, 1fr);

    .card_item {
        cursor: pointer;
        background-color: $base-color-dark-grey;
        min-height: 274px;
        border-radius: 20px;
        overflow: hidden;

        @media (hover: hover) {
            &:hover .card_name {
                color: $base-color-blue !important;
            }
        }

        .img_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 196px;
            background: linear-gradient(180deg, rgba(49, 51, 57, 0.00) 20%, #272728 100%);
        }

        .card_name {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 32px;

            color: #FFF;
            font-family: Nunito Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-align: center;
        }
    }
}

@media screen and (max-width: $xl-size-max-width) {
    .profile_page .card_list {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .profile_page .card_list {
        grid-template-columns: repeat(2, 1fr);
    }
}