@import "styles/common_styles/index";

.switch-block {
    background-color: $base-color-dark-grey;
    border-radius: 20px;

    &.bckColor2 {
        background-color: rgba(110, 112, 116, 0.2);
    }

    .my_button:not(.active) {
        background-color: transparent;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .switch-block {
        border-radius: 13px;
    }
}