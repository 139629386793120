@import "styles/common_styles/index";

.documents_card_list {
    margin-top: 18px;
    display: grid;
    grid-gap: 18px;
    grid-gap: 18px;
    width: 100%;
    padding: 0 58px;
    grid-template-columns: repeat(3, 1fr);

    &.single_list {
        grid-template-columns: repeat(1, 1fr);

        .card_item {
            max-width: 300px;
            margin: 0 auto;
            width: 100%;
        }
    }

    .card_item {
        cursor: pointer;
        background-color: $base-color-dark-grey-2;
        min-height: 274px;
        border-radius: 20px;
        overflow: hidden;

        @media (hover: hover) {
            &:hover .card_name {
                color: $base-color-blue !important;
            }
        }

        .img_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            min-height: 200px;
            background: linear-gradient(180deg, rgba(49, 51, 57, 0.00) 20%, #272728 100%);

            .subhead {
                display: flex;
                align-items: center;
                padding: 8px 14px;
                border-radius: 20px;
                margin-bottom: 24px;
                margin-top: -16px;

                span {
                    margin-left: 8px;
                    color: #FFF;
                    font-family: Nunito Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                }

                &.not_confirmed {
                    background: linear-gradient(90deg, #B64545 0.11%, rgba(182, 69, 69, 0.00) 107.02%);
                }

                &.processing_documents {
                    background: linear-gradient(90deg, $base-color-grey 0.11%, rgba(182, 69, 69, 0.00) 107.02%);
                }

                &.confirmed {
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);
                }
            }
        }

        .card_name {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 32px;

            color: #FFF;
            font-family: Nunito Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-align: center;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .documents_card_list {
        padding: 0 32px;
        grid-template-columns: repeat(1, 1fr);
    }
}