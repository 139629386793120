@import "styles/common_styles/index";

.search_block {
    border-radius: 20px;
    background-color: $base-color-dark-grey;

    @media (hover: hover) {
        &:hover {
            svg path {
                fill: $base-color-blue;
            } 

            input {
                color: #fff;
            }
        }
    } 

    svg {
        margin-left: 20px;
    }

    input {
        padding: 14px 26px 14px 16px;
        background-color: transparent;
        border: none;
        width: 100%;
        outline: none;

        color: $base-color-grey;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.24px;

        &:placeholder-shown {
            text-overflow: ellipsis;
            opacity: .5;
        }

        &::-webkit-search-cancel-button {
            display: none;
        }
    }

    .my_button {
        width: fit-content;
        height: 100%;
    }
}