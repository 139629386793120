@import "styles/common_styles/index";

.logo {
    padding: 26px 40px 18px;
    margin-bottom: 20px;
    max-width: 100%;

    img {
        max-width: 100%;
    }
}

@media screen and (max-width: $lg-size-max-width) {
    .logo {
        margin-right: 44px;
        padding: 0;
        margin-bottom: 0;

        img {
            max-height: 35px;
        }
    }
}

@media screen and (max-width: $md-size-max-width) {
    .logo {
        margin-right: 16px;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .logo {
        margin-right: 0;
    }
}