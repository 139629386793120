@import "styles/common_styles/index";

.my_button {
    height: fit-content;
    width: 100%;
    padding: 14px 23px;
    background-color: $base-color-dark-grey;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 20px;
    justify-content: center;
    cursor: pointer;

    &.full_height {
        height: 100%;
    }


    @media (hover: hover) {
        &.active:hover {
            background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%), linear-gradient(267deg, rgba(0, 200, 220, 0.00) -49.21%, rgba(58, 198, 212, 0.30) 176.87%);
        }


        &:not([data-off=true]) {
            &:not(.active):hover {
                svg path {
                    fill: $base-color-blue;
                }

                span {
                    background: linear-gradient(88deg, $base-color-blue -71.01%, $base-color-light 165.34%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    svg {
        margin-right: 10px;
    }

    span {
        color: $base-color-grey;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.24px;
    }

    &.medium {
        padding: 13px 22px;

        span {
            font-size: 16px;
        }
    }

    &.small {
        padding: 12px 14px;

        span {
            font-size: 14px;
        }
    }

    &.active {
        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, #3AC6D4 176.87%);

        svg path {
            fill: #fff;
        }

        span {
            color: #fff;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .my_button {
        border-radius: 13px;

        span {
            font-size: 16px;
        }

        &.medium span {
            font-size: 14px;
        }

        &.small span {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .my_button {
        padding: 14px 8px;

        span {
            font-size: 14px;
        }

        &.medium {
            padding: 13px 8px;

            span {
                font-size: 12px;
            }
        }

        &.small {
            padding: 12px 8px;

            span {
                font-size: 10px;
            }
        }
    }
}