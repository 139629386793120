@import "styles/common_styles/index";

.sidebar.filter_sidebar {
    background: #313439;
    padding: 32px 20px 108px 26px;

    .filter_menu {
        padding: 0;
        position: relative;
        border-radius: initial;
        background-color: initial;
        min-height: initial;
        max-height: initial;

        &::before {
            display: none;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .sidebar.filter_sidebar {
        padding: 32px 20px 82px 26px;
    }
}