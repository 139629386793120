@import "styles/common_styles/index";

.support_service {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 40px;

    .block_name {
        color: #FFF;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 25.197px;
        font-style: normal;
        font-weight: 800;
        line-height: 34.198px;
    }

    .service_list {
        margin-top: 24px;
        display: flex;

        .service {
            width: 100%;
            border-radius: 15px;
            background: linear-gradient(90deg, #FFF -1.16%, rgba(255, 255, 255, 0.00) 112.17%);
            padding: 16px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;

            @media (hover: hover) {
                &:hover {
                    background: linear-gradient(90deg, $base-color-blue -1.16%, rgba(255, 255, 255, 0.00) 112.17%);
                }
            }

            &:last-of-type {
                margin-right: 0;
            }

            svg {
                margin-right: 16px;
            }

            .title {
                color: $base-color-dark-grey-2;
                font-family: Nunito Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .support_service .service_list .service {
        margin-right: 0;
        margin-bottom: 22px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .support_service {
        padding: 0 20px;

        .block_name {
            font-size: 18px;
        }

        .service_list {
            margin-top: 16px;
        }
    } 
}