@import "styles/common_styles/index";

.error_wrapper {
    border-radius: 15px;
    background: linear-gradient(89deg, $base-color-red -185.19%, rgba(249, 57, 57, 0.98) -178.62%, rgba(249, 57, 57, 0.00) 130.19%);
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    ul {
        margin-left: 16px;

        li {
            color: #FFF;
            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;
            margin-bottom: 4px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}