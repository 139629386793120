@import "styles/common_styles/index";

.no-events_wrapper {
    background-color: $base-color-dark-grey;
    border-radius: 13px;
    padding: 70px;
    width: 100%;
    margin-top: 8px;

    .no-events {
        max-width: 500px;

        svg {
            margin-bottom: 20px;
        }

        .title {
            color: #FFF;
            font-family: Nunito Sans;
            font-size: 35.747px;
            font-style: normal;
            font-weight: 800;
            line-height: 34.198px;
            margin-bottom: 16px;
        }

        .subtitle {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 500;
            line-height: 22.715px;
            margin-bottom: 16px;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .no-events_wrapper {
        padding: 32px 40px;

        .no-events {
            svg, .title, .subtitle {
                margin-bottom: 10px;
            }
            
            .title {
                font-size: 24px;
            }
        }
    }
}