@import "styles/common_styles/index";

.bet-coef {
    width: 100%;
    padding: 10px 32px 10px 0;
    border-right: 1px solid $base-color-grey;
    margin-left: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        padding-right: 0;
        border-right: none;
    }

    .title {
        padding-right: 4px;
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
        flex-grow: 1;
        word-break: break-word;
    }

    .coef {
        color: #FFF;
        text-align: right;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
    }
}


@media screen and (max-width: $sm-size-max-width) {
    .bet-coef {
        padding: 10px 8px 10px 0;
        margin-left: 8px;

        .title, .coef {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .bet-coef .title {
        margin-bottom: 6px;
        padding-right: 0;
    }
}