@import "styles/common_styles/index";

.item_live_wrapper {
    padding-top: 22px;
    padding-bottom: 16px;
    width: 100%;
    border-bottom: 1px solid $base-color-grey;
    user-select: none;

    &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
    }

    &.active {
        .item>svg {
            transform: rotate(180deg) translateX(-4px);
        }

        .item_submenu {
            max-height: 2000px;
        }
    }

    .item {
        cursor: pointer;
        position: relative;

        @media (hover: hover) {
            &:hover {
                .title_box {
                    svg path {
                        fill: $base-color-blue;
                    }

                    .item_title {
                        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, #3AC6D4 16.87%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                &>svg path {
                    stroke: $base-color-blue;
                }
            }
        }

        .title_box {
            svg {
                min-width: 30px;
                min-height: 30px;
            }

            .item_title {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                margin-left: 12px;
            }
        }

        &>svg {
            min-width: 24px;
            padding-left: 4px;
        }
    }

    .item_submenu {
        padding-top: 0;
        max-height: 0;
        overflow: hidden;
        width: 100%;
    }
}