@import "styles/common_styles/index";

.or {
    margin-top: 22px;
    position: relative;
    display: flex;
    justify-content: center;

    span {
        padding: 0 14px;
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        z-index: 10;
        position: relative;
        background-color: #313439;
    }

    &::after {
        z-index: 1;
        top: 50%;
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: $base-color-grey;
    }
}

.sign-in-methods {
    display: flex;
    width: 100%;
    margin-top: 24px;

    .method {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-content: center;
        margin-right: 10px;
        padding: 16px 12px;
        border-radius: 15px;
        background-color: $base-color-dark-grey-2;
        width: 100%;

        @media (hover: hover) {
            &:hover {
                background-color: $base-color-blue !important;

                svg path {
                    fill: #fff !important;
                }
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}