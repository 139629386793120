@import "styles/common_styles/index";

.bet {
    .edit_bet_wrapper {
        // margin-top: 26px;
        margin-bottom: 18px;
        border-radius: 20px;
        background-color: rgba(110, 112, 116, 0.2);

        .switch-block {
            width: 100%;
            background-color: transparent;

            &.edit-active {
                max-width: 218px;
            }

            .input_wrapper {
                position: relative;

                &::after {
                    content: '';
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    width: 60%;
                    height: 2px;
                    display: block;
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);
                    bottom: 9px;
                }

                .bet_value_edit {
                    outline: none;
                    border: none;
                    width: 100%;
                    height: fit-content;
                    position: relative;

                    padding: 12px 6px;
                    border-radius: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;

                    color: $base-color-grey;
                    text-align: center;
                    font-family: Nunito Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: -0.24px;
                }
            }

            .my_button {
                padding: 12px 0;

                span {
                    max-width: 50px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .edit_bet {
            user-select: none;
            padding: 0 8px;
            min-width: 16px;
            box-sizing: content-box;

            @media (hover: hover) {
                &:hover path, &:hover rect {
                    fill: $base-color-blue;
                }
            }
        }
    }

    .bet_summa {
        margin-bottom: 27px;

        @media (hover: hover) {
            &:hover {
                .input_title {
                    background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .input_block {
                    border-color: $base-color-blue !important;

                    input {
                        color: #fff;
                    }
                }
            }
        }

        .input_title {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 140%;

            margin-bottom: 8px;
        }

        .input_block {
            border-radius: 20px;
            background-color: $base-color-dark-grey-2;
            border: 1px solid $base-color-grey;

            input {
                outline: none;
                border: none;
                padding: 16px 10px 16px 20px;
                background-color: transparent;
                width: 100%;

                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;

                &:placeholder-shown {
                    text-overflow: ellipsis;
                    opacity: .5;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            span {
                margin-right: 14px;
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;

                @media (hover: hover) {
                    &:hover {
                        color: $base-color-blue !important;
                    }
                }
            }
        }
    }

    &>.my_button {
        margin-top: 20px;
    }
}