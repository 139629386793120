@import "styles/common_styles/index";

.balance_alert_wrapper {
    padding: 0 60px;
    height: fit-content;
    width: 100%;
    margin-bottom: 36px;

    &+.my_button {
        max-width: 550px;
    }

    .balance_alert {
        padding: 20px 16px 20px 20px;
        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);
        display: flex;
        border: none;
        outline: none;
        border-radius: 20px;
        justify-content: center;

        &.unsuccess {
            background: linear-gradient(89deg, $base-color-red -185.19%, rgba(249, 57, 57, 0.00) 130.19%);
        }

        svg:not(.remove) {
            min-width: 70px;
            min-height: 70px;
            margin-right: 24px;
        }

        .title {
            color: #FFF;
            font-family: Nunito Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 110%;
            margin-bottom: 6px;
        }

        .subtitle {
            color: #DDD;
            font-family: Nunito Sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 300;
            line-height: 122%;
        }

        svg.remove {
            cursor: pointer;
            min-width: 20px;
            min-height: 20px;
            margin-right: 0;
            margin-left: auto;

            @media (hover: hover) {
                &:hover {

                    path,
                    rect {
                        fill: $base-color-blue;
                    }
                }
            }

            path,
            rect {
                fill: #FFF;
            }
        }
    }
}