@import "styles/common_styles/index";

.sort_block {
    border-radius: 10px;
    background: $base-color-dark-grey-2;
    position: relative;
    height: fit-content;
    width: fit-content;

    .sort_btn {
        padding: 8px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;

        @media (hover: hover) {
            &:hover {
                span {
                    color: $base-color-blue;
                }

                svg path {
                    stroke: $base-color-blue;
                }
            }
        }

        span {
            color: #FFF;
            text-align: center;
            font-family: Nunito Sans;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.24px;
        }

        svg {
            margin-left: 22px;
        }
    }

    &.open .sort_window {
        opacity: 1;
        max-height: 500px;
        padding: 16px 18px;
    }

    .sort_window {
        z-index: 1;
        display: flex;
        flex-direction: column;
        top: 120%;
        right: 0;
        width: 160%;
        opacity: 0;
        max-height: 0px;
        padding: 0 18px;
        overflow: hidden;
        position: absolute;
        background-color: $base-color-dark-grey;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out, padding 0.5s ease-in-out;

        .input_wrapper {
            cursor: pointer;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }

            @media (hover: hover) {
                &:hover {
                    .input_title {
                        color: $base-color-blue;
                    }

                    input::after {
                        border: 2px solid $base-color-blue;
                    }

                }
            }

            input {
                background: transparent;
                border: 0;
                box-sizing: border-box;
                display: inline;
                float: left;
                margin-left: 0;
                margin-right: 20px;
                width: 0;
                z-index: 1;
                height: 20px;

                &:checked::after {
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'%3E%3Cpath d='M0.914062 3.83276L4.50067 7.42012L10.5516 1.36914' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E");
                    background-position: 50%;
                    background-repeat: no-repeat;
                    border: none;
                }

                &::after {
                    cursor: pointer;
                    border: 1px solid $base-color-grey;
                    background-color: $base-color-dark-grey;
                    content: "";
                    display: block;
                    height: 100%;
                    width: 20px;
                    border-radius: 5px;
                    box-sizing: border-box;
                }
            }

            .input_title {
                user-select: none;
                margin-left: 8px;
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 12.838px;
                font-style: normal;
                font-weight: 400;
            }
        }
    }
}