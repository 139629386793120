@import "styles/common_styles/index";

.history_wrapper .history_block {
    &::before {
        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, hsla(0, 0%, 100%, 0.3) 176.87%);
        display: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 105%;
        height: 100%;
    }

    @media (hover: hover) {
        &:hover {
            cursor: pointer;
            
            &::before {
                display: block;
            }

            .index {
                background-color: $base-color-blue !important;
                color: #FFF !important;
            }
        }
    }
}

.history_block {
    padding: 14px 16px 20px;
    position: relative;

    &:last-of-type {
        margin-bottom: 16px;
    }

    span {
        font-family: Nunito Sans;
        font-style: normal;
        line-height: 140%;
    }

    .index {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $base-color-dark-grey-2;
        border-radius: 50%;
        color: $base-color-grey;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
    }

    .id {
        margin-left: 4px;
        color: #697077;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;

        max-width: 92px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .data {
        color: $base-color-grey;
        font-size: 14px;
        font-weight: 300;
    }

    .team {
        margin-top: 8px;
        margin-bottom: 6px;
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
    }

    .express_btn {
        cursor: pointer;
        transform: rotate(180deg);
        max-width: 24px;
        max-height: 24px;

        @media (hover: hover) {
            &:hover path {
                fill: $base-color-blue;
            }
        }
    }

    .sum_title {
        color: #697077;
        font-size: 14px;
        font-weight: 300;
    }

    .payment {
        color: $base-color-blue;
        font-size: 14px;
        font-weight: 700;

        &.unknown {
            color: #FFF;
        }

        &.loss {
            color: $base-color-red;
        }
    }

    .express_coef {
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
    }

    &::after {
        content: '';
        display: block;
        height: 1px;
        background-color: $base-color-grey;
        width: 125%;
        margin-left: -50px;
        position: absolute;
        bottom: 0;
    }
}