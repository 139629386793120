@import "styles/common_styles/index";

.category_page {
    .mobile_filter_btn {
        width: fit-content;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        padding: 6px 12px;
        border-radius: 20px;
        margin-left: auto;
        background: $base-color-dark-grey-2;

        @media (hover: hover) {
            &:hover {
                background: $base-color-blue;

                svg rect {
                    fill: #FFF;
                }

                .title {
                    color: #FFF;
                }
            }
        }

        svg {
            max-width: 24px;
            max-height: 24px;
            margin-bottom: 3px;
        }

        .title {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .page_title {
        margin-top: 48px;
        color: #FFF;
        font-family: Nunito Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;

        span {
            text-transform: uppercase;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .category_page .page_title {
        font-size: 18px;
    }
}