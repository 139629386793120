@import "styles/common_styles/index";

.history_table {
    padding-top: 18px;

    .pagination {
        margin: 0 auto;
        width: fit-content;

        .item, .arrow {
            margin: 0 8px;
        }
    }
}