@import "styles/common_styles/index";

.live_grid {
    width: fit-content;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-column-gap: 14px;
    grid-row-gap: 10px;
    margin-right: 20px;

    .col_item {
        color: #FFF;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;

        &.col_title {
            padding-bottom: 4px;
            color: $base-color-grey;
        }
    }
}