@import "styles/common_styles/index";

.lang_wrapper {
    position: relative;

    button {
        @media (hover: hover) {
            &:hover {
                background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%);

                span {
                    color: #fff;
                    background: initial !important;
                    -webkit-text-fill-color: initial !important;
                }
            }
        }
    }

    &.open {
        button {
            background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%);
            span {
                color: #fff;
                background: initial !important;
                -webkit-text-fill-color: initial !important;
            }
        }

        .lang_window {
            opacity: 1;
            max-height: 150px;
            padding: 10px 26px;
        }
    } 

    .lang_window {
        max-height: 0px;
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        padding: 0 26px;
        background-color: $base-color-dark-grey;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.60);
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 20px;
        transition:
            opacity .5s ease-in-out,
            max-height .5s ease-in-out,
            padding .5s ease-in-out, ;
        opacity: 0;

        .lang {
            cursor: pointer;
            color: $base-color-grey;
            text-align: center;
            font-family: Nunito Sans;
            font-size: 15.081px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            margin: 4px 0;

            @media (hover: hover) {
                &:hover {
                    color: $base-color-blue;
                }
            }
        }
    }
}