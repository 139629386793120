@import "styles/common_styles/index";

.breadcrumb {
    align-items: center;

    .main_page {
        margin-bottom: 4px;

        svg {
            max-width: 28px;
            max-height: 18px;
            margin-right: 4px;
        }
    }

    .page_wrapper {
        margin-bottom: 4px;
    }

    .page {
        color: $base-color-grey;
        display: flex;
        align-items: stretch;
        margin-right: 8px;

        @media (hover: hover) {
            &:not(.current_page):hover {
                color: $base-color-blue;
            }
        }

        &.current_page {
            color: #FFF;
            font-weight: 700;
        }
    }

    .page,
    .divider {
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

    .divider {
        margin-right: 8px;
    }



    .prev_page {
        display: flex;
        align-items: center;
        margin-left: auto;
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                svg {
                    transform: translateX(-3px);

                    path {
                        stroke: $base-color-blue;
                    }
                }

                span {
                    color: $base-color-blue;
                }
            }
        }

        svg {
            transition: .8s transform ease-in-out;
            margin-right: 8px;
        }

        span {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .breadcrumb {
        .prev_page {
            margin-left: initial;
        }

        .homepage.hide-if_sm_size {
            display: none;
        }

        .page.hide-if_sm_size {
            display: none;
        }
    }
}