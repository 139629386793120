@import "styles/common_styles/index";

.category_card {
    min-width: 280px;
    max-width: 100%;
    width: 100%;
    margin: 0 7px;
    border-radius: 13px;
    background: $base-color-dark-grey;

    @media (hover: hover) {
        &:hover {
            background-color: $base-color-dark-grey-2;

            .card_name {
                color: $base-color-grey-2;
                background-color: #313339;
            }
        }
    }

    .card_name {
        border-radius: 13px 13px 0px 0px;
        padding: 6px;
        background-color: $base-color-dark-grey-2;

        color: #a2a2a2;
        // color: $base-color-grey;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
    }

    .main_info {
        margin-top: 22px;
        margin-bottom: 24px;
        padding: 0 28px;
        cursor: pointer;

        .team {
            max-width: 30%;
            width: 100%;

            img {
                max-width: 65px;
                max-height: 55px;
                object-fit: cover;
                margin-bottom: 12px;
            }

            .team_name {
                color: #FFF;
                text-align: center;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        .date_wrapper {
            margin: 0 4px;

            .date {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            .time {
                color: #FFF;
                text-align: center;
                font-family: Nunito Sans;
                font-size: 27.25px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }

            .vs {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 100%;
            }
        }
    }

    hr {
        height: 0;
        border-bottom: 0 solid $base-color-grey;
        opacity: .6;
        width: 100%;
    }

    .coef_wrapper {
        .coef_block {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 26px 0 28px;

            &:first-of-type {
                border-bottom-left-radius: 13px;
            }

            &:last-of-type {
                border-bottom-right-radius: 13px;
            }

            @media (hover: hover) {
                &:not(.is_stoped):hover {
                    cursor: pointer;
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);

                    .title {
                        color: #FFF;
                    }
                }
            }

            &.active:not(.is_stoped) {
                background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);

                .title {
                    color: #FFF;
                }
            }

            .title {
                margin-right: 4px;
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }

            .coef {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 800;
                line-height: 100%;
            }
        }
    }
}