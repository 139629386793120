@import "styles/common_styles/index";

.match_list {
    margin-top: 28px;
    border-radius: 13px;
    overflow: hidden;

    .list_title {
        background-color: rgb(110 112 116 / 26%);
        padding: 10px 20px;

        color: #FFF;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .match_list {
        margin-top: 20px;
    }
}