@import "styles/common_styles/index";

.coef_box {
    display: flex;
    align-items: center;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    line-height: 100%;
    padding: 10px 8px;
    border-radius: 10px;
    margin-right: 14px;
    flex-grow: 1;
    justify-content: center;

    &.active:not(.is_stoped) {
        cursor: pointer;
        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);

        .coefTitle {
            color: #fff;
        }
    }

    @media (hover: hover) {
        &:not(.T-block):not(.is_stoped):hover {
            cursor: pointer;
            background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);

            .coefTitle {
                color: #fff;
            }
        }
    }

    &:last-of-type {
        margin-right: 0;
    }

    &.T-block {
        flex-grow: initial;
        background-color: rgba(110, 112, 116, 0.2);
        padding: 10px 20px;
        border-radius: 20px;
    }

    .coefTitle {
        margin-right: 4px;
        color: $base-color-grey;
        font-weight: 400;
    }

    .value {
        color: #fff;
        font-weight: 800;
    }
}

@media screen and (max-width: $xl-size-max-width) {
    .coef_box {
        flex-direction: column;

        &.T-block {
            flex-direction: row;

            .coefTitle {
                margin-right: 4px;
                margin-bottom: initial;
            }
        }

        .coefTitle {
            margin-right: 0;
            margin-bottom: 4px;
        }
    }
}

@media screen and (max-width: $lg-size-max-width) {
    .coef_box {
        flex-direction: row;

        .coefTitle {
            margin-right: 4px;
            margin-bottom: initial;
        }
    }
}

@media screen and (max-width: $md-size-max-width) {
    .coef_box {
        flex-direction: column;

        &.T-block {
            flex-direction: row;

            .coefTitle {
                margin-right: 4px;
                margin-bottom: initial;
            }
        }

        .coefTitle {
            margin-right: 0;
            margin-bottom: 4px;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .coef_box {
        flex-direction: row;
        margin: 0;

        &.T-block {
            flex-grow: 1;
            padding: 6px 14px;
        }

        .coefTitle {
            margin-right: 4px;
            margin-bottom: initial;
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .coef_box {
        flex-direction: column;

        &.T-block {
            flex-direction: column;

            .coefTitle {
                margin-right: 4px;
                margin-bottom: initial;
            }
        }

        .coefTitle {
            margin-right: 0;
            margin-bottom: 4px;
        }
    }
}