@import "styles/common_styles/index";

.form_container {
    width: 100%;
    border-radius: 13px;
    background-color: $base-color-dark-grey;
    padding: 44px 54px 50px;

    &>.title {
        color: #FFF;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 35.747px;
        font-style: normal;
        font-weight: 800;
        line-height: 34.198px;
    }

    .balance_alert_wrapper {
        padding: 0;
        margin-top: 16px;
        margin-bottom: 0;

        .balance_alert {
            justify-content: initial;
        }
    }

    .error_wrapper {
        margin-top: 16px;
    }

    form {
        margin-top: 32px;
        flex-wrap: wrap;

        &.password_tab {
            max-width: 550px;
            margin: 32px auto 0;

            .col_labels:first-of-type {
                max-width: 100%;
                margin-right: 0;
            }

            .submit_btn {
                max-width: inherit;
            }
        }

        .row_labels {
            max-width: 100%;
            width: 100%;
        }

        .col_labels {
            width: 100%;
            max-width: calc(50% - 18px);

            &:first-of-type {
                margin-right: 36px;
            }

            .input_wrapper {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                .password_wrapper {
                    position: relative;

                    input {
                        width: 100%;
                        padding-right: 52px;
                    }

                    svg {
                        user-select: none;
                        cursor: pointer;
                        position: absolute;
                        right: 18px;
                        top: 50%;
                        transform: translateY(-50%);

                        @media (hover: hover) {
                            &:hover path {
                                fill: $base-color-blue;
                            }
                        }
                    }
                }

                .input_text {
                    color: #FFF;
                    font-family: Nunito Sans;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 140%;
                    margin-bottom: 10px;
                }

                input {
                    outline: none;
                    border-radius: 15px;
                    background-color: $base-color-dark-grey-2;
                    border: 1px solid $base-color-grey;
                    padding: 14px 20px;

                    color: #FFF;
                    font-family: Nunito Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;

                    &[disabled] {
                        opacity: .7;
                    }

                    &::-webkit-calendar-picker-indicator {
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
                    }

                    &::placeholder {
                        text-overflow: ellipsis;
                        opacity: .5;
                    }
                }
            }
        }

        .submit_btn {
            height: 100%;
            max-width: 50%;
            width: 50%;
            border: 15px;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .form_container .title {
        font-size: 19px;
        line-height: 100%;
    }

    .form_container {
        padding: 20px 18px 28px;

        form {
            margin-top: 16px;

            .col_labels {
                margin-right: 0 !important;
                max-width: 100%;

                .input_wrapper {
                    margin-bottom: 25px;
                }
            }

            .submit_btn {
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
}