@import "styles/common_styles/index";

.profile_page {
    .title_page {
        svg {
            margin-right: 12px;
        }

        span {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}