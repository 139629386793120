@import "styles/common_styles/index";

.filter_menu {
    padding: 50px 10px 48px 32px;
    right: 0%;
    min-height: calc(100vh - 56px);
    max-height: 95vh;
    height: 100%;
    background-color: #3D3F44;
    border-radius: 20px;
    width: 100%;
    top: 0;
    position: absolute;
    transition: .5s right ease-in-out;
    // overflow-x: hidden;
    // overflow-y: auto;

    &::before {
        z-index: -1;
        content: '';
        position: absolute;
        width: 73px;
        height: 100%;
        border-radius: 21px;
        top: 0;
        left: 0;
        background-color: inherit;
        display: block;
        transition: .4s left ease-in;
    }

    // &::-webkit-scrollbar {
    //     width: 8px;
    //     background-color: $base-color-dark;
    // }

    // &::-webkit-scrollbar-thumb {
    //     background-color: #464649;
    //     border-radius: 8px;
    // }

    // &::-webkit-scrollbar-track {
    //     background-color: #8f9196;
    //     border-radius: 8px;
    // }

    &.active {
        right: -100%;

        &::before {
            left: -36px;
        }
    }

    .filter_clear_wrapper {
        @media (hover: hover) {
            &:hover {
                &>svg path {
                    fill: $base-color-blue;
                }

                .filter_clear {
                    color: $base-color-blue;
                }
            }
        }
    }

    .filter_clear {
        margin-left: 6px;
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }

    .close_filter_menu {
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        background-color: $base-color-grey;
        border-radius: 50%;
        margin-left: auto;

        @media (hover: hover) {
            &:hover {
                background-color: $base-color-blue;
            }
        }

        svg path {
            stroke: $base-color-dark-grey;
        }
    }
}