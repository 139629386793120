@import "styles/common_styles/index";

.bet .coef_block {

    .title {
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 11.233px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .input_block {
        margin-top: 8px;

        .input_wrapper {
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    .input_title {
                        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -10.21%, $base-color-blue 10.87%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    input::after {
                        border: 3px solid $base-color-blue;
                    }

                }
            }

            input {
                background: transparent;
                border: 0;
                box-sizing: border-box;
                display: inline;
                float: left;
                margin-left: 0;
                margin-right: 20px;
                width: 0;
                z-index: 1;
                height: 20px;

                &:checked::after {
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'%3E%3Cpath d='M0.914062 3.83276L4.50067 7.42012L10.5516 1.36914' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E");
                    background-position: 50%;
                    background-repeat: no-repeat;
                    border: none;
                }

                &::after {
                    cursor: pointer;
                    border: 1px solid $base-color-grey;
                    background-color: $base-color-dark-grey;
                    content: "";
                    display: block;
                    height: 100%;
                    width: 20px;
                    border-radius: 5px;
                    box-sizing: border-box;
                }
            }

            .input_title {
                margin-left: 8px;
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 12.838px;
                font-style: normal;
                font-weight: 400;
            }
        }
    }
}