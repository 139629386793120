@import "styles/common_styles/index";

.sort_wrapper {
    margin-bottom: 18px;
    align-items: center;

    h2 {
        flex-grow: 1;
        color: #FFF;
        font-family: Nunito Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }

    .sort_block {
        margin-left: 12px;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .sort_wrapper {
        align-items: flex-start;
        flex-wrap: wrap;

        h2 {
            margin-bottom: 24px;
            width: 100%;
        }

        .sort_block {
            margin-left: 0;
            margin-right: 12px;
            margin-bottom: 4px;
        }

        .sort_block .sort_window {
            right: initial;
            left: 0;
        }
    }
}