@import "styles/common_styles/index";

.coupon {
    flex-grow: 1;
    overflow: hidden;
    padding: 32px 17px 0;
    position: relative;
    background: linear-gradient(180deg, $base-color-dark-grey 80%, #272728 100%);

    .express_sidebar {
        background: linear-gradient(180deg, $base-color-dark-grey 80%, #272728 100%);
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 17px 8px;
        position: absolute;
        right: -100%;
        top: 144px;
        transition: right 0.6s;
        width: 100%;
        height: calc(100% - 144px);
        z-index: 110;

        &::-webkit-scrollbar {
            width: 5px;
            box-shadow: 0px 4px 44px rgba(132, 132, 132, 0.473);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $base-color-grey;
            border-radius: 8px;

            @media (hover: hover) {
                &:hover {
                    background-color: $base-color-blue;
                }
            }
        }

        &::-webkit-scrollbar-track {
            border-radius: 13px;
            background-color: rgba(110, 112, 116, 0.3);
        }

        &.active {
            right: 0;
            transition: right 0.6s ease;
        }

        .head {
            margin-bottom: 6px;

            .express_button {
                cursor: pointer;
                max-width: 24px;
                max-height: 24px;

                @media (hover: hover) {
                    &:hover path {
                        fill: $base-color-blue;
                    }
                }
            }

            .total_coef {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 900;
                line-height: 100%;
                margin-bottom: 6px;
            }
        }

        .history_block {
            padding: 14px 0 20px;
        }

        .express_sum {
            margin-top: 14px;
            font-family: Nunito Sans;

            .sum_title {
                color: #697077;
                font-size: 14px;
                font-weight: 300;
            }

            .payment {
                color: $base-color-blue;
                font-size: 14px;
                font-weight: 700;

                &.unknown {
                    color: #FFF;
                }

                &.loss {
                    color: $base-color-red;
                }
            }
        }

        &>.my_button {
            margin-top: 14px;
        }
    }

    .bet_cards .total_express_coef {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        
        color: #FFF;
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;

        .value {
            font-weight: 700;
        }
    }

    .bet_cards,
    .history_wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 8px;
        position: relative;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $base-color-grey;
            border-radius: 8px;

            @media (hover: hover) {
                &:hover {
                    background-color: $base-color-blue;
                }
            }
        }

        &::-webkit-scrollbar-track {
            border-radius: 13px;
            background-color: rgba(110, 112, 116, 0.3);
        }
    }

    .coupon_no-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 6px;
        padding: 0 8px;

        svg {
            height: 52px;
            width: 30px;
            margin-bottom: 4px;
        }

        .title {
            color: $base-color-grey;
            text-align: center;
            font-family: Nunito Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 34.198px;
        }

        .subtitle {
            color: $base-color-grey;
            text-align: center;
            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }
    }

    .remove_all {
        padding: 20px 0;
        margin-top: auto;

        @media (hover: hover) {
            &:hover {
                svg path {
                    fill: $base-color-blue;
                }

                .title {
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 46.87%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        svg {
            margin-right: 8px;
        }

        .title {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
        }
    }
}