@import "styles/common_styles/index";

.filter {
    padding-right: 10px;
    max-height: calc(100% - 65px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 25px;

    &::-webkit-scrollbar {
        width: 5px;
        background-color: $base-color-dark;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #464649;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: #8f9196;
        border-radius: 6px;
    }

    &.active {
        .head {
            margin-bottom: 16px;

            svg {
                // transform: rotate(180deg) translateX(-4px);
                transform: rotate(180deg);
            }
        }

        .tags_wrapper {
            max-height: 2000px;
        }
    }

    .head {
        margin-bottom: 0;
        cursor: pointer;
        user-select: none;

        img {
            width: 24px;
            height: 24px;
        }

        .title {
            margin-left: 7px;
            color: #FFF;
            font-family: Nunito Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
        }

        svg {
            margin-left: auto;
            max-width: 14px;
            max-height: 10px;

            path {
                stroke: #FFF;
            }
        }

        @media (hover: hover) {
            &:hover {
                .title {
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, #3AC6D4 16.87%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                svg path {
                    stroke: $base-color-blue !important;
                }
            }
        }
    }

    .tags_wrapper {
        max-height: 0;
        overflow: hidden;

        .input_wrapper {
            cursor: pointer;
            margin-bottom: 7px;
            user-select: none;

            @media (hover: hover) {
                &:hover {
                    .input_title {
                        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -10.21%, $base-color-blue 10.87%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    input::after {
                        border: 3px solid $base-color-blue;
                    }

                }
            }

            input {
                width: 0;
                height: 20px;
                background: transparent;
                border: 0;
                box-sizing: border-box;
                display: inline;
                float: left;
                margin-left: 0;
                margin-right: 20px;

                &:checked::after {
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'%3E%3Cpath d='M0.914062 3.83276L4.50067 7.42012L10.5516 1.36914' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E");
                    background-position: 50%;
                    background-repeat: no-repeat;
                    border: none;
                }

                &::after {
                    cursor: pointer;
                    border: 1px solid $base-color-grey;
                    content: "";
                    display: block;
                    height: 100%;
                    width: 20px;
                    border-radius: 5px;
                    box-sizing: border-box;
                }
            }

            .input_title {
                margin-left: 10px;
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
            }
        }
    }
}

.no_filters {
    margin-top: 32px;

    svg {
        margin-bottom: 8px;
    }

    .title {
        color: $base-color-grey;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 34.198px;
    }

    .subtitle {
        color: $base-color-grey;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
}

@media screen and (max-width: $lg-size-max-width) {
    .filter {
        max-height: calc(100% - 36px);
    }
}