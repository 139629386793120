@import "styles/common_styles/index";

.search_page {
    .search_block {
        width: 100%;
        margin-top: 22px;
        display: none;
    }

    .tab_menu-search {
        margin: 16px 0 22px;
        border-radius: 13px;
        background-color: $base-color-dark-grey;
        overflow: hidden;

        .tab_list {
            overflow-x: auto;

            &::-webkit-scrollbar {
                height: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #464649;
                border-radius: 8px 8px 0 0;
            }

            &::-webkit-scrollbar-track {
                background-color: #8f9196;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            .tab {
                padding: 16px 32px;
                background-color: $base-color-dark-grey;
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                cursor: pointer;
                white-space: nowrap;

                @media (hover: hover) {
                    &:not(.active):hover {
                        background: linear-gradient(88deg, $base-color-blue-2 -1.56%, #72F2FF 127.21%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }

                &.active {
                    color: #FFF;
                    border-radius: 12px;
                    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);
                }
            }
        }
    }

    .result_search {
        display: block;
        margin: 54px 0 20px;
        color: #FFF;
        font-family: Nunito Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .search_page {
        .tab_menu-search .tab_list .tab {
            padding: 14px 28px;
        }

        .search_block {
            display: flex;
        }
    }
}