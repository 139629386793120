@import "styles/common_styles/index";

$animation-duration-open: .5s; // + JS
$animation-delay-open: .2s; // + JS
$animation-timing-function: cubic-bezier(0.55, 0.04, 0.05, 0.97);

@mixin animate-item($n) {
    transition:
        opacity $animation-duration-open * ($n * 0.8) $animation-timing-function (
        $animation-delay-open + ($animation-duration-open * .7)),
        top $animation-duration-open * ($n * 0.8) $animation-timing-function $animation-delay-open;
    top: -100 * $n * 1 + 0px;
}

footer {
    overflow: hidden;
    flex-grow: 1;
    position: relative;
    background-color: #313339;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 30px;
    margin-bottom: -28px;
    padding: 32px 50px;
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: repeat(4, 1fr
    );

&.active {
    .item_container {
        top: 0 !important;
        opacity: 1;
    }
}

.item_container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;

    @for $i from 1 through 10 {
        &:nth-of-type(#{$i}) {
            @include animate-item($i );
        }
    }

    opacity: 0;

    .item {

        .title {
            margin-bottom: 16px;
            color: #F4F4F4;
            font-family: Nunito Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            
            &.current_nav {
                color: $base-color-blue;
            }
        }

        svg {
            display: none;
        }
    }

    .item_submenu {
        max-height: 500px;
        overflow: hidden;

        .subtitle {
            cursor: pointer;
            margin-bottom: 16px;
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;

            &.current_nav {
                background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            @media (hover: hover) {
                &:hover {
                    background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}
}

@media screen and (max-width: $lg-size-max-width) {
    footer {
        margin-bottom: 82px;
        border-radius: 20px;
        min-height: 260px;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    footer {
        min-height: initial;
        margin-top: 40px;
        grid-template-columns: repeat(1, 1fr);
        padding: 22px 28px;
        margin-bottom: 66px;

        .item_container {
            margin-bottom: 20px;
            padding-top: 18px;
            padding-bottom: 18px;

            width: 100%;
            border-bottom: 1px solid $base-color-grey;

            &:last-of-type {
                border-bottom: none;
                padding-bottom: 0;
            }

            &.active {
                .item svg {
                    transform: rotate(180deg);
                }

                .item_submenu {
                    padding-top: 16px;
                    max-height: 500px;
                }
            }

            .item {
                cursor: pointer;

                .title {
                    margin-bottom: 0;
                }

                svg {
                    display: block;
                }
            }

            .item_submenu {
                padding-top: 0;
                max-height: 0;

                .subtitle {
                    margin: 6px 0;
                }
            }
        }
    }
}