@import "styles/common_styles/index";

.bet {
    display: flex;
    flex-direction: column;
    background-color: $base-color-dark-grey;
    height: 100%;
    max-height: 76px;
    min-height: 76px;
    overflow: hidden;
    padding: 32px 16px 34px 18px;
    transition: .3s min-height ease-in-out, .3s max-height ease-in-out;

    &.submit_block .scroll_wrapper>.my_button {
        opacity: .5;
        cursor: default;
        pointer-events: none;
    }

    .scroll_wrapper>.my_button {
        margin-bottom: 22px;
    }

    &.no-bets {

        .edit_bet_wrapper,
        .bet_summa {
            display: none;
        }

        .my_button.no-bets_btn {
            display: none;
        }

        &.open {
            min-height: 160px;
            max-height: 160px;
        }

        &.no-auth.open {
            min-height: 230px;
            max-height: 230px;
        }
    }

    .scroll_wrapper {
        margin-top: 26px;
        max-height: calc(100% - 12px);
        height: 100%;
        padding-right: 8px;
        // padding-bottom: 20px;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $base-color-grey;
            border-radius: 8px;

            @media (hover: hover) {
                &:hover {
                    background-color: $base-color-blue;
                }
            }
        }

        &::-webkit-scrollbar-track {
            border-radius: 13px;
            background-color: rgba(110, 112, 116, 0.3);
        }
    }

    &.open {
        max-height: 410px;
        min-height: 410px;

        .scroll_wrapper {
            overflow-y: auto;
        }

        &.form_success {
            min-height: 232px;
            max-height: 232px;
        }

        &.bet_alert_active {
            min-height: 476px;
            max-height: 476px;
        }

        .block_title svg {
            transform: rotate(0);
        }
    }

    .min_bet {
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 140%;
        width: 100%;
        text-align: center;
        display: block;
        margin-top: -24px;
        margin-bottom: -12px;
    }

    .bet_alert {
        margin-top: 26px;
        height: fit-content;
        width: 100%;
        padding: 14px 23px;
        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, #3AC6D4 176.87%);
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        border-radius: 20px;
        justify-content: center;
        margin-bottom: 20px;

        &.success, &.invalid_coupon {
            margin-top: 0;
        }

        &.unsuccess {
            background: linear-gradient(89deg, $base-color-red -185.19%, rgba(249, 57, 57, 0.00) 130.19%);
        }

        svg:not(.remove) {
            min-width: 24px;
            max-width: 24px;
            min-height: 24px;
            max-height: 24px;
            margin-right: 8px;
        }

        span {
            color: #FFF;
            font-family: Nunito Sans;
            font-size: 13.761px;
            font-style: normal;
            font-weight: 300;
            line-height: 100%;
            letter-spacing: 0.43px;
        }

        svg.remove {
            cursor: pointer;
            margin-right: 0;
            margin-left: 12px;

            @media (hover: hover) {
                &:hover {
                    opacity: .3;
                }
            }

            path,
            rect {
                fill: #fff;
            }
        }
    }
}