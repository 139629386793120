@import "styles/common_styles/index";

.cash-withdrawal {
    .balance_alert_wrapper {
        margin-top: 24px;
        margin-bottom: 0;
        padding: 0 20px;
    }

    .form_wrapper {
        width: 100%;
        padding: 0 20px;
        margin-top: 20px;
        justify-content: center;

        .balance_form {
            max-width: min(60%, 550px);
        }

        .text_info {
            margin-top: 8px;
            margin-left: 16px;

            .title {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                margin-bottom: 10px;
            }

            .subtitle {
                color: $base-color-grey-2;
                font-family: Nunito Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 140%;
                margin-bottom: 11px;
            }
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .cash-withdrawal {
        .form_wrapper {
            .balance_form {
                max-width: 550px;
                padding: 0;
            }

            .text_info {
                margin-top: 24px;
                margin-left: 0;
            }
        }
    }
}