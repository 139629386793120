@import "styles/common_styles/index";

.sidebar.coupon_sidebar {
    background: #313439;
    padding: 0 0 86px;
    z-index: 109;
}

@media screen and (max-width: $sm-size-max-width) {
    .sidebar.coupon_sidebar {
        padding: 0 0 72px;
    }
}