@import "styles/common_styles/index";

.verified_wrapper {
    width: 95%;
    z-index: 10000;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 28px;
    background-color: $base-color-dark-grey-2;
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.6);
    padding: 24px 40px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

    .close_modal {
        position: absolute;
        right: 10px;
        top: 10px;

        @media (hover: hover) {
            &:hover {
                svg path:first-of-type {
                    fill: $base-color-blue;
                }
            }
        }
    }

    .title {
        color: #FFF;
        font-family: "Nunito Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        a {
            color: inherit;
            font-weight: 800;
        }
    }
}