@import "styles/common_styles/index";

.text-info_page .content_block {
    margin-top: 32px;
    overflow: hidden;

    img {
        width: 100%;
        min-height: 150px;
        object-fit: cover;
    }

    .inner_block {
        padding: 40px 36px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .body_text {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 300;
            line-height: 150%;
        }

        p, li, h1, h2, h3, h4, h5, h6 {
            margin-bottom: 10px;
        }

        a {
            color: #FFF;
        }

        ul,
        ol {
            margin-left: 24px;
        }

        ul li::marker {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 300;
            line-height: 130%;
        }

        ol {
            li {
                list-style-type: none;
                counter-increment: list;
                position: relative;
            }

            li::before {
                content: counter(list) ".";
                position: absolute;
                left: -2.5em;
                width: 2em;
                text-align: right;

                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 17px;
                font-style: normal;
                font-weight: 300;
                line-height: 130%;
            }
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .text-info_page .content_block {
        .inner_block {
            padding: 20px 28px 40px;
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .text-info_page .content_block {
        .inner_block {
            padding: 12px 20px 40px;

            ul,
            ol {
                margin-left: 16px;
            }

            ol li::before {
                font-size: 13px;
            }

            span {
                font-size: 13px;
            }
        }
    }
}