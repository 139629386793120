@import "styles/common_styles/index";

.slider_wrapper {
    margin-top: 36px;

    &:first-child {
        margin-top: 24px;
    }

    .slider_header {
        margin-bottom: 16px;

        .title {
            svg {
                margin-right: 12px;
                min-width: 30px;
                min-height: 30px;
            }

            span {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
            }
        }

        .prev_page {
            display: flex;
            align-items: center;
            margin-left: auto;
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    svg {
                        transform: translateX(-3px);

                        path {
                            stroke: $base-color-blue;
                        }
                    }

                    span {
                        color: $base-color-blue;
                    }
                }
            }

            svg {
                transition: .8s transform ease-in-out;
                margin-right: 8px;
            }

            span {
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        a {
            @media (hover: hover) {
                &:hover {
                    span {
                        background: linear-gradient(88deg, $base-color-blue -71.01%, $base-color-light 165.34%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    svg path {
                        stroke: $base-color-blue;
                    }
                }
            }

            span {
                margin-right: 4px;
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 17px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }

    .category_slider {
        .slick-track {
            display: flex;
        }

        .slick-slide {
            height: initial;

            &>div {
                height: 100%;
                display: flex;
                justify-content: center;
            }
        }

        .slick-arrow {
            width: 40px;
            height: 40px;
            background: $base-color-grey;
            border-radius: 50%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 4px 44px rgba(39, 39, 40, 0.47);

            @media (hover: hover) {
                &:hover {
                    background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%) !important;

                    svg path {
                        stroke: #FFF;
                    }
                }
            }

            svg {
                min-height: 17px;
                min-width: 11px;

                path {
                    stroke: $base-color-dark-grey;
                }
            }

            &.slick-next {
                right: -15px;
            }

            &.slick-prev {
                z-index: 1;
                left: -15px;
            }
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .slider_wrapper {
        padding-bottom: 64px;

        .slider_header a span {
            font-size: 13px;
        }

        .category_slider .slick-arrow {
            top: initial;
            bottom: -105px;
            width: 65px;
            height: 65px;

            svg {
                min-height: 24px;
                min-width: 18px;
            }

            &.slick-prev {
                left: 10px;
            }

            &.slick-next {
                right: 10px;
            }
        }
    }
}