@import "styles/common_styles/index";

.avatar_upload {
    margin-right: 36px;
    width: 50%;

    .image_upload_wrapper {
        background-color: $base-color-dark-grey-2;
        border-radius: 20px;
    
        .image_title {
            padding: 0 32px;
            display: flex;
            align-items: center;
            color: #848484;
            font-family: "Nunito Sans";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            max-width: 200px;
        }
    
        .my_button {
            background: linear-gradient(90deg, #FFF -1.16%, rgba(255, 255, 255, 0.00) 112.17%);
    
            @media (hover: hover) {
                &:hover {
                    background: linear-gradient(88deg, $base-color-blue -1.56%, #72F2FF 127.21%), linear-gradient(267deg, rgba(0, 200, 220, 0) -49.21%, rgba(58, 198, 212, 0.3) 176.87%) !important;
                }
            }
        }
    }
    
    .avatar_errors_block {
        margin-top: 8px;
    
        span {
            display: flex;
            align-items: center;
    
            color: $base-color-red;
            font-family: Nunito sans;
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
        }
    }
}



@media screen and (max-width: $xs-size-max-width) {
    .avatar_upload .button_wrapper {
        height: fit-content;
        flex-direction: column;

        .my_button:first-of-type {
            margin-right: 0px;
            margin-bottom: 10px;
        }
    }
}