@import "styles/common_styles/index";

.theme_mode_2 {
    user-select: none;
    padding: 4px 12px;
    border-radius: 13px;
    background-color: #313439;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
}

.theme_mode {
    margin: 0 auto 32px;
    user-select: none;

    .switch_block {
        background: #fff;
        border-radius: 50px;
        width: 44px;
        height: 20px;
        position: relative;
        margin-right: 12px;
        padding: 0 4px 0 6px;

        &::after {
            content: '';
            display: block;
            background-color: #2F3035;
            width: 16px;
            height: 15px;
            border-radius: 50%;
            position: absolute;
            right: 3px;
            top: 50%;
            transform: translateY(-50%);
            transition: all .7s ease-in-out;
        }

        @media (hover: hover) {
            &:hover {
                background: linear-gradient(88deg, $base-color-blue -71.01%, $base-color-light 165.34%), linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%);
            }
        }
    }

    .theme_title {
        color: $base-color-grey;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
    }
}