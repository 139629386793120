@import "styles/common_styles/index";


.mobile_fixed_window {
    position: fixed;
    width: calc(100%);
    z-index: 106;
    left: 0;
    height: fit-content;
    margin-top: -16px;
    padding: 16px 24px 16px;
    background-color: $base-color-dark;

    .logo_wrapper {
        border-radius: 20px;
        background-color: $base-color-dark-grey;
        padding: 6px 20px 4px 28px;
        margin-right: 10px;

        .theme_mode {
            margin: initial;
            flex-direction: column;

            .switch_block {
                width: 34px;
                height: 16px;
                margin-right: 0;

                svg {
                    max-width: 11px;
                    max-height: 11px;
                }

                &::after {
                    width: 12px;
                    height: 12px;
                }
            }

            .theme_title {
                font-size: 12px;
            }
        }
    }

    .search_block {
        width: initial;
        flex-grow: 1;
        // margin-right: 10px;
    }

    .lang_wrapper, .login_lang {
        margin-left: 10px;
    }

    .auth_wrapper {
        position: relative;

        &>.my_button {
            padding: 4px 12px;

            svg {
                min-width: 38px;
                height: auto;
                margin-right: 0;
            }
        }

        &.open {
            &>.my_button {
                background-color: $base-color-blue;

                svg path {
                    fill: #FFF !important;
                }
            }

            .auth_window {
                opacity: 1;
                max-height: 150px;
            }
        }

        .auth_window {
            width: 180px;
            max-height: 0px;
            overflow: hidden;
            left: 50%;
            top: 115%;
            transform: translateX(-50%);
            position: absolute;
            background-color: $base-color-dark-grey;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.60);
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 20px;
            transition:
                opacity .5s ease-in-out,
                max-height .5s ease-in-out,
                padding .5s ease-in-out, ;
            opacity: 0;

            .my_button {
                border-radius: 20px;
            }
        }
    }

    .mobile_btn {
        border-radius: 13px;
        background-color: $base-color-dark-grey;
        user-select: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 8px;
        min-width: 50px;

        &.active {
            .burger {

                i,
                &::before,
                &::after {
                    height: 3px;
                    background-color: $base-color-blue;
                }

                i {
                    width: 0;
                    opacity: 0;
                }

                &::before {
                    top: 10px;
                    transform: rotate(-135deg);
                }

                &::after {
                    bottom: 7px;
                    transform: rotate(315deg);
                }
            }

            svg path {
                fill: $base-color-blue;
            }
        }

        .burger {
            height: 20px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before,
            &::after {
                content: '';
                position: absolute;
                transition: .8s all ease-in-out;
            }

            i {
                opacity: 1;
                transition: .6s all ease-in;
            }

            i,
            &::before,
            &::after {
                border-radius: 20px;
                width: 22px;
                height: 4px;
                display: block;
                background-color: $base-color-grey;
            }

            &::before {
                top: 0;
            }

            &::after {
                bottom: 0;
            }
        }

        svg {
            max-width: 28px;
            width: 100%;
            max-height: 25px;
            height: 100%;
        }
    }

    .category_container {
        padding-left: 18px;
        // padding-right: 18px;
        margin-top: 10px;
        border-radius: 20px;
        background-color: $base-color-dark-grey;

        .category_wrapper {
            padding: 8px 10px;
            margin-right: 18px;
            overflow-x: auto;

            &::-webkit-scrollbar {
                height: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #464649;
                border-radius: 8px 8px 0 0;
            }

            &::-webkit-scrollbar-track {
                background-color: #8f9196;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            .category_item {
                margin-right: 30px;

                .inner_box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &.active {
                        svg path {
                            fill: $base-color-blue;
                        }

                        .title {
                            color: $base-color-blue;
                        }
                    }
                }

                svg {
                    flex-grow: 1;
                }

                .title {
                    max-width: 72px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;

                    margin-top: 4px;
                    color: $base-color-grey;
                    font-family: Nunito Sans;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .mobile_fixed_window {
        padding: 10px 13px 10px;
        margin-top: -10px;

        .logo_wrapper {
            border-radius: 13px;
            margin-right: auto;
            padding: 6px 14px 4px 18px;
        } 
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .mobile_fixed_window .logo_wrapper {
        padding: 3px 14px 3px 14px;
    }

    .mobile_fixed_window .auth_wrapper {
        position: initial;

        .auth_window {
            max-width: 95%;
            width: 100%;
            top: 45%;
        }
    }
}