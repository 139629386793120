@import "styles/common_styles/index";

.table_data {
    border-radius: 13px;
    background-color: $base-color-dark-grey;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    .item_wrapper {
        border-bottom: 1px solid $base-color-grey;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .item {
        padding: 14px 40px 16px 26px;
        display: flex;
        position: relative;

        .state,
        .date {
            max-width: 32%;
        }

        .sum {
            max-width: 24%;
        }

        .id {
            max-width: 12%;
            min-width: 100px;
        }

        .col {
            padding: 0 4px;
            width: initial;

            .payment {
                color: $base-color-blue;

                &.unknown {
                    color: #FFF;
                }

                &.loss {
                    color: $base-color-red;
                }
            }

            &.col-team {
                min-width: 160px;
                max-width: 250px;
            }

            &.col-date {
                min-width: 120px;
                max-width: 200px;
            }

            &.col-small {
                max-width: 100px;
            }

            &.col-medium {
                max-width: 200px;
            }

            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }

        .state,
        .date,
        .sum,
        .id,
        .col {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: 100%;

            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            line-height: 140%;

            h5 {
                margin: 0;
                color: $base-color-grey;
                font-weight: 400;
                font-size: inherit;
            }

            span {
                color: #FFF;
                font-weight: 700;

                &::first-letter {
                    text-transform: capitalize;
                }
            }
        }

        .accordion_btn {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);

            @media (hover: hover) {
                &:hover path {
                    stroke: $base-color-blue;
                }
            }
        }
    }

    .accordion {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 58px;
        overflow: hidden;
        transition: max-height .3s ease-in-out, padding-bottom .3s ease-in-out;
        max-height: 0;

        .item {
            padding: 0;
            padding: 6px 0;
        }

        &.open {
            padding-bottom: 16px;
        }
    }
}

@media screen and (max-width: $md-size-max-width) {
    .table_data {
        .item {
            padding: 14px 40px 16px 20px;
        }

        .accordion {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 52px;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .table_data {
        .item {
            flex-wrap: wrap;
            padding: 14px 16px 20px 14px;

            .accordion_btn {
                top: 18px;
                transform: initial;
            }

            .state,
            .date,
            .col:nth-of-type(-n+4) {
                margin-bottom: 16px;
            }

            .col {
                max-width: 50% !important;

                &.col-date {
                    min-width: initial;
                }
            }

            .col:nth-of-type(odd) {
                padding-left: 0;
                min-width: 160px;
            }

            .state,
            .date,
            .sum,
            .id {
                max-width: 50%;
            }
        }

        .accordion {
            padding-left: 14px;
            padding-bottom: 0;
            padding-top: 0;

            &.open {
                padding-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .table_data .item {
        .col {
            max-width: 100% !important;
            padding-left: 0;
            margin-bottom: 16px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}