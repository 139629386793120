@import "styles/common_styles/index";

.consultant_wrapper {
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-dark -49.18%, $base-color-blue 176.87%);
    position: fixed;
    right: 16px;
    bottom: 40px;
    cursor: pointer;
    z-index: 101;
} 