@import "styles/common_styles/index";

.document_upload_page .content_block {
    margin-top: 32px;
    overflow: hidden;
    padding: 45px 54px 50px;
    display: flex;

    .img_wrapper {
        border-radius: 20px;
        background: $base-color-dark-grey-2;
        padding: 20px 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 40%;
        width: 100%;

        img {
            max-height: 350px;
            width: 100%;
            object-fit: contain;
        }
    }

    .inner_block {
        margin-left: 52px;
        display: flex;
        flex-direction: column;

        .title {
            margin-top: 30px;
            margin-bottom: 16px;
            color: #FFF;
            font-family: Nunito Sans;
            font-size: 35.747px;
            font-style: normal;
            font-weight: 800;
            line-height: 34.198px;
        }

        .criteria {
            margin-bottom: 20px;
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 27.7px;

            ul {
                list-style-position: inside;
            }
        }
    }
}

@media screen and (max-width: $xl-size-max-width) {
    .document_upload_page .content_block {
        padding: 25px 32px 40px;

        .img_wrapper {
            max-width: 50%;
        }

        .inner_block {
            margin-left: 32px;
        }
    }
}

@media screen and (max-width: $md-size-max-width) {
    .document_upload_page .content_block {
        flex-direction: column;

        .img_wrapper {
            max-width: 100%;

            img {
                max-height: 400px;
            }
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .document_upload_page .content_block {
        .img_wrapper {
            max-width: 100%;

            img {
                max-height: 350px;
            }
        }

        .inner_block {
            margin-left: 0;

            .title {
                font-size: 25px;
                margin-top: 24px;
                margin-bottom: 12px;
            }
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .document_upload_page .content_block {
        padding: 25px 32px 40px;

        .img_wrapper {
            padding: 12px 32px;

            img {
                max-height: 250px;
            }
        }

        .inner_block {
            .title {
                font-size: 18px;
                line-height: 22.2px;
            }

            .criteria {
                font-size: 13px;
                line-height: 150%;
            }
        }
    }
}