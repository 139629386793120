@import "styles/common_styles/index";

.balance_form {
    margin-top: 8px;
    max-width: 520px;
    width: 100%;
    padding: 0 20px;

    .bet_summa {
        margin-bottom: 24px;

        .input_title {
            color: #FFF;
            font-family: Nunito Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 140%;

            margin-bottom: 8px;
        }

        .input_block {
            border-radius: 20px;
            background-color: $base-color-dark-grey-2;
            border: 1px solid $base-color-grey;

            @media (hover: hover) {
                &:hover {
                    border-color: $base-color-blue;

                    input {
                        color: #fff;
                    }
                }
            }

            input {
                outline: none;
                border: none;
                padding: 16px 10px 16px 20px;
                background-color: transparent;
                width: 100%;

                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;

                &:placeholder-shown {
                    text-overflow: ellipsis;
                    opacity: .5;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
        }
    }

    .edit_bet_wrapper {
        margin-bottom: 28px;
        border-radius: 20px;
        background-color: rgba(110, 112, 116, 0.2);

        .switch-block {
            width: 100%;
            background-color: transparent;

            .my_button {
                padding: 12px 0;

                span {
                    max-width: 50px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &>.my_button {
        height: 58px;

        &.blocked {
            pointer-events: none;
            cursor: default;
            opacity: .5;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .balance_form {
        margin-top: 16px;

        &>.my_button {
            height: fit-content;
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .balance_form .bet_summa .input_block input {
        padding: 12px 10px 12px 20px;
    }
}