@import "styles/common_styles/index";

.profile_page_title {
    word-break: break-word;
    color: #FFF;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 35.747px;
    font-style: normal;
    font-weight: 800;
    line-height: 34.198px;
    margin: 0 0 18px 0;
}

.profile_page_subtitle {
    max-width: 750px;
    padding: 0 16px;
    color: $base-color-grey;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 17.228px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.715px;
    margin: 0 0 18px 0;
}

@media screen and (max-width: $sm-size-max-width) {
    .profile_page_title {
        font-size: 25px;
    }

    .profile_page_subtitle {
        margin: 6px 0;
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .profile_page_title {
        font-size: 18px;
    }

    .profile_page_subtitle {
        font-size: 13px;
        margin: 2px 0;
    }
}