@import "styles/common_styles/index";

.match {
    background-color: $base-color-dark-grey;
    padding: 12px 20px;
    border-bottom: 1px solid $base-color-grey;
    display: flex;

    &:last-of-type {
        border-bottom: 0;
    }

    .team_block {
        padding-right: 32px;
        flex-grow: 1;

        .live_match {
            color: $base-color-grey;
            font-family: Nunito Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin-bottom: 8px;
        }

        .team {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            &:last-of-type {
                margin-bottom: 0;
            }

            img {
                max-width: 20px;
                max-height: 20px;
                margin-right: 12px;
            }

            .team_name {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }
    }

    .coef_wrapper {
        padding: 0 20px;
        border-left: 1px solid $base-color-grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 330px;
        width: 100%;
    }

    &.live .coef_wrapper {
        max-width: 300px;
    }
}

@media screen and (max-width: 1420px) {
    .live .coef_box.T-block {
        display: none;
    }
}



@media screen and (max-width: $lg-size-max-width) {
    .live .coef_box.T-block {
        display: flex;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .match .team_block {
        margin-bottom: 12px;
        width: 100%;
        padding-right: 0;
        align-items: center;
        flex-wrap: wrap;

        .live_match {
            margin-bottom: 0;
            order: 1;
            margin-left: auto;
            width: 100%;
            order: -1;
            margin-bottom: 6px;
        }

        .team {
            margin-bottom: 0;
            margin-right: 8px;

            img {
                margin-right: 8px;
            }
        }
    }

    .match .live_grid {
        display: none;
    }

    .match .coef_wrapper {
        max-width: 100% !important;
        border: none;
        padding: 8px 0;
    }
}