@import "styles/common_styles/index";

header.desktop_header {
    position: fixed;
    margin-top: -28px;
    padding: 28px 0 18px 32px;
    background-color: $base-color-dark;
    height: fit-content;
    z-index: 100;
    width: calc(100% - ($nav-menu-width + 60px));
    margin-left: $nav-menu-width;

    .auth_search {
        margin-right: 10px;
    }

    .login_lang {
        margin-left: 32px;
    }

    &>.switch-block {
        min-width: 400px;
    }
}