@import "styles/common_styles/index";

.sign_form {
    min-height: calc(100vh - 56px);
    // margin-top: -2px;

    .content_inner {
        width: 100%;
        max-width: 500px;
    }

    .content {
        background-color: $base-color-dark-grey;
        border-radius: 20px 0 0 20px;
        padding: 50px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        form {
            display: flex;
            flex-direction: column;
            margin-top: 32px;

            &>svg {
                margin-bottom: 32px;
            }

            .title {
                word-break: break-word;
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 42px;
                font-style: normal;
                font-weight: 900;
                line-height: 110%;
                margin-bottom: 18px;
            }

            .subtitle {
                margin-bottom: 16px;
                color: $base-color-grey;
                font-family: Nunito Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 140%;
            }

            .fieldset {
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;

                @media (hover: hover) {
                    &:hover {
                        label {
                            background: linear-gradient(88deg, #209CA9 -1.56%, #72F2FF 127.21%);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }

                        input {
                            border: 1px solid #209CA9;
                            color: #fff;
                        }
                    }
                }

                .password_wrapper {
                    position: relative;

                    input {
                        width: 100%;
                        padding-right: 52px;
                    }

                    svg {
                        user-select: none;
                        cursor: pointer;
                        position: absolute;
                        right: 18px;
                        top: 50%;
                        transform: translateY(-50%);

                        @media (hover: hover) {
                            &:hover path {
                                fill: $base-color-blue;
                            }
                        }
                    }
                }

                label {
                    margin-bottom: 6px;
                    color: #FFF;
                    font-family: Nunito Sans;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 140%;
                }

                input {
                    border-radius: 15px;
                    border: 1px solid $base-color-grey;
                    background: $base-color-dark-grey-2;
                    outline: none;
                    padding: 14px 20px;

                    color: $base-color-grey;
                    font-family: Nunito Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                }
            }

            .my_button {
                border-radius: 15px;
                margin-top: 18px;
            }

            .sign-in-block {
                margin-top: 28px;

                color: #FFF;
                font-family: Nunito Sans;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 21px;

                label {
                    input {
                        background: transparent;
                        border: 0;
                        box-sizing: border-box;
                        display: inline;
                        float: left;
                        margin-left: 0;
                        margin-right: 20px;
                        width: 0;
                        z-index: 1;
                        height: 20px;

                        @media (hover: hover) {
                            &:hover::after {
                                border: 3px solid $base-color-blue;
                            }
                        }

                        &:checked::after {
                            background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -49.21%, $base-color-blue 176.87%), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9' fill='none'%3E%3Cpath d='M0.914062 3.83276L4.50067 7.42012L10.5516 1.36914' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E");
                            background-position: 50%;
                            background-repeat: no-repeat;
                            border: none;
                        }

                        &::after {
                            border: 1px solid #DFDFDF;
                            background-color: $base-color-grey;
                            content: "";
                            cursor: pointer;
                            display: block;
                            height: 100%;
                            width: 20px;
                            border-radius: 5px;
                            box-sizing: border-box;
                        }
                    }

                    span {
                        margin-left: 8px;
                        user-select: none;
                    }
                }

                u {
                    text-decoration: underline;
                }

                a {
                    color: #FFF;
                    font-weight: 800;

                    @media (hover: hover) {
                        &:hover {
                            color: $base-color-blue !important;
                        }
                    }
                }
            }
        }
    }

    .poster_wrapper {
        overflow: hidden;
        border-radius: 0 20px 20px 0;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: $lg-size-max-width) {
    .content_block_wrapper.sign_form {
        height: 100%;
        padding-bottom: 82px;
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .content_block_wrapper.sign_form {
        padding-bottom: 100px;

        .content {
            width: 100%;
            border-radius: 20px 20px 0 0;
        }

        .poster_wrapper {
            width: 100%;
            border-radius: 0 0 20px 20px;
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .sign_form .content {
        padding: 48px 28px;

        form .title {
            font-size: 35px;
        }
    }
}

@media screen and (max-width: $xs-size-max-width) {
    .sign_form .content form .title {
        font-size: 24px;
    }
}