@import "styles/common_styles/index";

.main_slider {
    .slick-slide {
        padding: 0 3px;
    }

    .slide {
        display: inline-flex !important;
        border: 3px solid #313439;
        width: 100%;
        height: 262px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        border-radius: 20px;
        overflow: hidden;
        padding: 32px 30px 24px;
        position: relative;

        video {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
        }


        .text_wrapper {
            color: #FFF;
            font-family: Nunito Sans;
            font-style: normal;
            line-height: 140%;
            max-width: 500px;

            h1 {
                font-size: 30px;
                font-weight: 800;
                margin: 0;
                margin-bottom: 16px;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                margin: 0;
            }
        }

        .read_more {
            z-index: 1;
            display: flex;
            align-items: center;
            width: fit-content;

            @media (hover: hover) {
                &:hover {
                    span {
                        background: linear-gradient(267deg, rgba(114, 242, 255, 0.00) -199.21%, #3AC6D4 176.87%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    svg {
                        margin-left: 10px;

                        path {
                            fill: $base-color-blue !important;
                        }
                    }
                }
            }

            span {
                color: #FFF;
                font-family: Nunito Sans;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
            }

            svg {
                transition: margin-left .8s ease-out;
                margin-left: 6px;
            }
        }
    }

    .slick-arrow {
        top: 32px;
        width: 10px;
        height: 18px;

        @media (hover: hover) {
            &:hover {
                svg path {
                    stroke: $base-color-blue !important;
                }
            }
        }

        svg path {
            stroke: #fff;
        }

        &.slick-next {
            right: 30px;
        }

        &.slick-prev {
            z-index: 1;
            left: initial;
            right: 54px;
        }
    }

    .slick-dots {
        bottom: 28px;
        right: 28px;
        width: fit-content;
        display: flex !important;
        align-items: flex-end;

        li {
            width: 12px;
            height: 12px;
            background: #fff;
            border-radius: 3px;
            padding: 0;
            margin: 0 3px;

            @media (hover: hover) {
                &:hover {
                    background: linear-gradient(88deg, $base-color-blue -71.01%, $base-color-light 165.34%) !important;
                }
            }

            &.slick-active {
                width: 20px;
                height: 20px;
            }

            button {
                padding: 0;
                width: 10px;
                height: 10px;

                &::before {
                    content: '';
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: $sm-size-max-width) {
    .main_slider .slide {
        padding: 22px 16px 24px;

        .text_wrapper {
            max-width: 80%;

            h1 {
                font-size: 18px;
                margin-bottom: 6px;
            }

            p {
                font-size: 14px;
            }
        }
    }
}