@import "styles/common_styles/index";

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-top: 24px;
    margin-top: auto;
    max-width: 350px;
    width: 100%;

    .arrow {
        background-color: $base-color-dark-grey-2;

        &.prev {
            order: -1;
        }

        &.next {
            order: 3;
        }

        @media (hover: hover) {
            &:hover {
                background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%);

                svg path {
                    stroke: #fff;
                }
            }
        }
    }

    .arrow,
    .item {
        cursor: pointer;
        // width: 44px;
        width: 37px;
        height: 40px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    .item {
        margin: 0 3px;
        color: $base-color-grey;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;

        &.hide {
            display: none;
        }

        &.active {
            color: #fff;
            background-color: $base-color-dark-grey-2;

            @media (hover: hover) {
                &:hover {
                    background: linear-gradient(88deg, $base-color-blue -1.56%, $base-color-light 127.21%), $base-color-dark-grey-2;
                }
            }
        }
    }
}