@import "styles/common_styles/index";

.tablet_menu_wrapper {
    position: fixed;
    width: 100%;
    z-index: 109;
    left: 0;
    height: fit-content;
    padding: 12px 24px 16px;
    bottom: 0;
    background-color: $base-color-dark;

    &.menu_active .tablet_menu {
        background-color: $base-color-dark-grey;
    }

    .tablet_menu {
        width: 100%;
        border-radius: 20px 36px 36px 20px;

        .btn_open_window {
            flex-direction: column;
            max-width: 93px;
            max-height: 70px;
            margin-right: 8px;

            svg {
                margin: 0;
            }

            span {
                margin-top: 4px;
                font-size: 12px;
                line-height: 11px;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        .consultant_wrapper {
            position: relative;
            bottom: 0;
            right: 0;
        }
    }
}