@import "styles/common_styles/index";

$animation-duration-open: .9s; // + JS
$animation-duration-close: .5s; // + JS
$animation-delay-open: .8s; // + JS
$animation-timing-function: cubic-bezier(0.55, 0.04, 0.05, 0.97);

@mixin animate-item($n) {
    transition:
        opacity $animation-duration-open * ($n * 0.3) $animation-timing-function $animation-delay-open,
        top $animation-duration-open * ($n * 0.3) $animation-timing-function $animation-delay-open,
        padding-top $animation-duration-open * ($n * 0.3) $animation-timing-function $animation-delay-open,
        padding-bottom $animation-duration-open * ($n * 0.3) $animation-timing-function $animation-delay-open;
}

.top-sidebar {
    &.active .menu {
        .item_wrapper {
            opacity: 1;
            padding-top: 18px;
            padding-bottom: 18px;
            top: 0;

            @for $i from 1 through 10 {
                &:nth-of-type(#{$i}) {
                    @include animate-item($i);
                }
            }

            &.active {
                .item_submenu {
                    padding-top: 16px;
                    max-height: 500px;
                }
            }
        }
    }

    .search_block {
        width: 100%;

        input {
            padding: 10px 16px;
        }
    }

    .menu {
        margin-top: 8px;
        padding: 0 20px;

        .item_wrapper {
            border-bottom: 1px solid $base-color-dark-grey;
            padding-right: 16px;
            padding-left: 24px;
            position: relative;

            transition:
                opacity $animation-duration-close $animation-timing-function,
                top $animation-duration-close $animation-timing-function,
                padding-top $animation-duration-close $animation-timing-function,
                padding-bottom $animation-duration-close $animation-timing-function;
            top: -20px;
            opacity: 0;
            padding-top: 0;
            padding-bottom: 0;

            &.category_list {
                padding-left: 0;
                padding-right: 0;

                .has_submenu {
                    padding-right: 16px;
                    padding-left: 24px;
                }

                .category .inner_box {
                    padding: 16px;
                }
            }

            &:last-of-type {
                border-bottom: none;
            }

            &.support {
                svg {
                    max-width: 36px;
                    max-height: 32px;

                    path {
                        fill: $base-color-grey;
                    }
                }
            }

            .theme_mode {
                margin: initial;

                .switch_block {
                    margin-right: 0;
                }
            }

            &.active {
                .item {
                    span {
                        color: $base-color-blue;
                    }

                    svg {
                        transform: rotate(180deg);

                        path {
                            stroke: $base-color-blue;
                        }
                    }
                }
            }

            .item {
                cursor: pointer;

                .has_submenu_title {
                    color: $base-color-grey;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Nunito Sans;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: -0.24px;
                }

                svg {
                    transition: .6s ease-in-out;
                    margin-left: 4px;
                }
            }

            .item_submenu {
                padding-top: 0;
                max-height: 0;
                overflow: hidden;
                transition:
                    max-height .8s cubic-bezier(0.62, 0.28, 0.39, 1) .2s,
                    padding-top .8s cubic-bezier(0.62, 0.28, 0.39, 1) .2s;
                width: 100%;

                &.lang_submenu {
                    transition:
                        max-height .4s cubic-bezier(0.62, 0.28, 0.39, 1) .15s,
                        padding-top .4s cubic-bezier(0.62, 0.28, 0.39, 1) .15s;
                }

                .category .filter_btn {
                    display: none;
                }

                .title {
                    cursor: pointer;
                    margin: 6px 0;
                    color: $base-color-grey;
                    font-family: Nunito Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    @media (hover: hover) {
                        &:hover {
                            color: $base-color-blue;
                        }
                    }
                }
            }
        }
    }
}